export enum ELoadState {
  READY = 'READY',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
}

export type TDiscoveryContentProps = {
  rootInitLoaded: boolean;
  logParam: any;
  scrollToEl?: (selector: string) => void;
};
