import {useMemo} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {TPlaceItem} from 'ducks/place/types';
import {EMarkerType, TMarker} from 'types/Map';
import {filterCustomMarker} from 'utils/marker';
import useMapMarkerConfig from './useMapMarkerConfig';
import {Anchor} from '@vsm/vsm';
import useCalloutInfoMarker from './useCalloutInfoMarker';

export const usePlaceMapMarker = (list, currentPoi) => {
  const {userPosition, calloutInfo, mapStyle, tRanking} = useAppSelector((state) => ({
    userPosition: state.map.userPosition,
    calloutInfo: state.userInteraction.calloutInfo,
    mapStyle: state.map.style,
    tRanking: state.tRank.data.list,
  }));

  const {markerStyleConfig} = useMapMarkerConfig();
  const isTmapRankingPage = !!tRanking.length;
  const calloutInfoMarker = useCalloutInfoMarker();

  const poiMarkers: TMarker<Partial<TPlaceItem>>[] = useMemo(() => {
    const markers = (list || []).map((item) => {
      const isActive = !calloutInfoMarker && currentPoi === item.listId;
      const {marker: customMarker, label} = filterCustomMarker(item, isActive, {
        mapStyle,
        markerConfig: markerStyleConfig,
      });

      return {
        properties: item,
        type: isActive ? EMarkerType.ACTIVE : EMarkerType.INACTIVE,
        clickable: true,
        description: label || item.poiName,
        lonLat: {
          lon: item.wgs84CenterX,
          lat: item.wgs84CenterY,
        },
        anchor: isActive ? 'bottom' : 'center',
        children: customMarker,
      };
    });

    return markers;
  }, [list, calloutInfoMarker, currentPoi, mapStyle, markerStyleConfig]);

  const currentPositionMarker: Nullable<TMarker<Partial<TPlaceItem>>> = useMemo(
    () =>
      userPosition
        ? {
            properties: {poiId: '-1'},
            type: EMarkerType.CURRENT_POSITION,
            clickable: false,
            description: '현재위치 마커',
            lonLat: userPosition,
            anchor: 'center',
          }
        : null,
    [userPosition]
  );

  const tmapRankingMarker: TMarker<Partial<TPlaceItem>>[] = useMemo(() => {
    const markers = tRanking.map((item, idx) => {
      const isActive = currentPoi === item.listId && !calloutInfo?.markerType;
      const {marker: customMarker, label} = filterCustomMarker(
        {...item, idx, isTampRank: true},
        isActive,
        {
          mapStyle,
          markerConfig: markerStyleConfig,
        }
      );

      return {
        properties: item,
        type: isActive ? EMarkerType.ACTIVE_RANKING : EMarkerType.INACTIVE_RANKING,
        clickable: true,
        description: label || item.poiName,
        lonLat: {
          lon: item.wgs84CenterX,
          lat: item.wgs84CenterY,
        },
        anchor: (isActive ? 'bottom' : 'center') as Anchor,
        children: customMarker,
        rank: item.rank,
      };
    });

    return markers;
  }, [tRanking, currentPoi, calloutInfo?.markerType, mapStyle, markerStyleConfig]);

  const markers = useMemo(() => {
    return (isTmapRankingPage ? [...tmapRankingMarker] : [...poiMarkers])
      .filter((v) => !!v)
      .concat(currentPositionMarker ? [currentPositionMarker] : []);
  }, [poiMarkers, currentPositionMarker, tmapRankingMarker, isTmapRankingPage]);

  const priorityIncludeMarker = useMemo(() => {
    return (
      calloutInfoMarker ||
      markers.find((m) =>
        [EMarkerType.ACTIVE, EMarkerType.ACTIVE_RANKING].includes(m?.type as EMarkerType)
      )
    );
  }, [calloutInfoMarker, markers]);

  return {
    markers,
    poiMarkers,
    currentPositionMarker,
    calloutInfoMarker,
    priorityIncludeMarker,
  };
};
