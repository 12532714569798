/**
 * 보조호출 용으로 주차에서 사용하는 인터페이스 모듈 가져옴 (TmapWebView 객체 사용부분 제거)
 * 공통 interface 라이브러리 사용전에 동시호출 문제발생시 임시적으로 사용하기 위함
 * 원본 - https://github.com/tmobi-internal/tmap-parking/blob/master/src/tmap-modules/tmap-interface.ts
 */
import qs from 'query-string';
import {findKey, map, uniqueId} from 'lodash';
import {TinyEmitter} from 'tiny-emitter';
import {isProdEnv} from 'constant/Env';
import semver from 'semver';
import ua from 'utils/uaParser';

export enum TmapInterfaceEvent {
  CALLED_DELAY_ACTION = 'CALLED_DELAY_ACTION',
  CALL_INTERFACE = 'CALL_INTERFACE',
  RECEIVED_CALLBACK = 'RECEIVED_CALLBACK',
  RUN_CALLBACK_BY_RESUME = 'RUN_CALLBACK_BY_RESUME',
}

enum TmapInterfaceType {
  SEND = 'SEND', // 단방향
  DIRECT_CALLBACK = 'DIRECT_CALLBACK', // 안드로이드 바로 리턴타입, IOS는 callback
  CALLBACK = 'CALLBACK', // 둘다 콜백
}

export enum NativeCallbackEvent {
  CALLED_STATIC_METHOD = 'CALLED_STATIC_METHOD',
  ON_PAUSE = 'ON_PAUSE',
  ON_RESUME = 'ON_RESUME',
  ON_GET_CURRENT_POSITION = 'ON_GET_CURRENT_POSITION',
}

interface InterfaceOptions {
  method: string;
  type: TmapInterfaceType;
  params?: any;
  delayAction?: boolean;
  /* checkCallbackByResume
  웹뷰를 여는 인터페이스의 경우 callback이 발생되지 않는 경우가 발생됨. 예) android > startPaymentActivity > 물리 백버튼
  callback이 발생되지 않더라도 사용처에서의 callback을 보장하기 위해 onResume이 발생하면 callback 호출여부 체크한 후 수동으로 callback 실행
  */
  checkCallbackByResume?: boolean;
  version: {
    ios: string;
    android: string;
  };
}

const IFRAME_ID_PREFIX = '__tmap_custom_url_call__';
const CALLBACK_FLAG = '__callback__';

class TmapInterface extends TinyEmitter {
  nativeCallback = window.TmapWebView.nativeCallback;
  nativeCallbackCounter = 0;
  osName: 'ios' | 'android';
  appVersion: string;
  isInApp = /\/tmap\//i.test(window.navigator.userAgent);

  constructor(osName: 'ios' | 'android', appVersion: string) {
    super();

    this.osName = osName;
    this.appVersion = appVersion;

    if (!this.nativeCallback) {
      this.nativeCallback = window.TmapWebView.nativeCallback = {};
    }
  }

  /************************************************************/
  /*************************** 구현부 ***************************/
  /************************************************************/
  _nativeCall(method: string, params?: any) {
    this.emit(TmapInterfaceEvent.CALL_INTERFACE, method, params);
    if (this.osName === 'ios') {
      let iframeContainer: Nullable<HTMLDivElement> =
        document.querySelector('#interface_container');
      if (!iframeContainer) {
        iframeContainer = document.createElement('div') as HTMLDivElement;
        iframeContainer.id = 'interface_container';
        iframeContainer.style.display = 'none';
        document.body.appendChild(iframeContainer);
      }

      const iframeId = `${IFRAME_ID_PREFIX}${method}${uniqueId('call_')}`;
      const iframe = document.createElement('IFRAME') as HTMLIFrameElement;
      iframe.id = iframeId;
      iframeContainer.appendChild(iframe);
      setTimeout(() => iframeContainer?.removeChild(iframe), 100);

      const paramString = qs.stringify(params);
      const customUrl = `tmapjs://${method}${paramString ? `?${paramString}` : ''}`;
      iframe.setAttribute('src', customUrl);
    } else {
      if (window.TmapApp[method]) {
        return window.TmapApp[method](...map(params, (val) => val));
      } else {
        if (!isProdEnv) {
          // eslint-disable-next-line no-console
          console.log(`NotFound interface - ${method}`);
        }
      }
    }
  }

  _createCallback(callback: (...args: any[]) => any) {
    const eventName = `event_${this.nativeCallbackCounter++}`;
    this.nativeCallback[eventName] = (...result: any[]) => {
      callback.call(null, ...result);
      delete this.nativeCallback[eventName];
    };
    return `TmapWebView.nativeCallback.${eventName}`;
  }

  _createInterfacePromise<T = null>(options: InterfaceOptions): Promise<T> {
    return new Promise((resolve) => {
      const interfaceVersion = options.version[this.osName];
      if (!interfaceVersion || !semver.gte(this.appVersion || '0.0.0', interfaceVersion)) {
        // eslint-disable-next-line no-console
        console.log(
          `Interface - Can not call the interface. method: ${options.method}, os: ${this.osName}, interfaceVersion: ${interfaceVersion}, currentAppVersion: ${this.appVersion}`
        );
        resolve([''] as any); // 이거 any 말고 T로 할 수 없나.
        return;
      }

      if (!this.isInApp) {
        // eslint-disable-next-line no-console
        console.log(
          `Interface - TmapApp required. method: ${options.method}, os: ${this.osName}, interfaceVersion: ${interfaceVersion}, currentAppVersion: ${this.appVersion}`
        );
        resolve([''] as any); // 이거 any 말고 T로 할 수 없나.
        return;
      }

      const createCallbackAndCall = () => {
        Object.keys(options.params).forEach((key) => {
          const val = options.params[key];
          if (val === CALLBACK_FLAG) {
            let callbackCalled = false;
            const generatedCallback = this._createCallback((...args) => {
              callbackCalled = true;
              this.emit(TmapInterfaceEvent.RECEIVED_CALLBACK, options.method, args);
              resolve(args as any);
            });
            // checkCallbackByResume - onResume 시 100ms 이후에도 callback이 실행되지 않았으면 수동 호출.
            if (options.checkCallbackByResume) {
              const onResumeCallback = () => {
                setTimeout(() => {
                  if (!callbackCalled) {
                    this.emit(TmapInterfaceEvent.RUN_CALLBACK_BY_RESUME, options.method);
                    eval(`${generatedCallback}('')`);
                  }
                }, 100);
              };
              this.once(NativeCallbackEvent.ON_RESUME, onResumeCallback);
            }
            options.params[key] = generatedCallback;
          }
        });
        this._nativeCall(options.method, options.params);
      };

      switch (options.type) {
        case TmapInterfaceType.SEND:
          this._nativeCall(options.method, options.params);
          resolve([''] as any);
          break;
        case TmapInterfaceType.DIRECT_CALLBACK:
          if (this.osName === 'android') {
            let callbackKey = findKey(options.params, (val) => val === CALLBACK_FLAG);
            if (callbackKey) {
              delete options.params[callbackKey];
            }
            const value = this._nativeCall(options.method, options.params);
            resolve([value] as any);
          } else {
            createCallbackAndCall();
          }
          break;
        case TmapInterfaceType.CALLBACK:
        default:
          createCallbackAndCall();
          break;
      }

      // 딜레이 발생여지가 있는 인터페이스 호출 후 발생시킨다. 뷰에서는 잠시 UI를 차단한다.
      if (options.delayAction) {
        this.emit(TmapInterfaceEvent.CALLED_DELAY_ACTION);
      }
    });
  }

  /************************************************************/
  /*************************** 선언부 ***************************/
  /************************************************************/

  getUserSetting(key: string) {
    return this._createInterfacePromise<[value: string]>({
      method: 'getUserSetting',
      type: TmapInterfaceType.DIRECT_CALLBACK,
      params: {key, callbackJS: CALLBACK_FLAG},
      version: {
        ios: '8.9.0',
        android: '8.9.0',
      },
    });
  }
}

export const TMapSenderCustom = new TmapInterface(ua.isIos ? 'ios' : 'android', ua.tmapAppVersion);
if (!isProdEnv) {
  // @ts-ignore
  window.TMapSenderCustom = TMapSenderCustom;
}
