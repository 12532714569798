import {EFilter} from 'components/FilterBottomPopup';
import {useAppSelector} from 'ducks/hooks';

const useSearchResult = () => {
  const {rdSearch} = useAppSelector((state) => ({
    rdSearch: state.search,
  }));

  const isLastPage = rdSearch.nowPage >= rdSearch.maxPage;
  // nowPage가 maxPage보다 큰 경우는 서버 응답 (totalCount === 0 && docs.length === 0)인 경우
  // https://tmobi.atlassian.net/browse/CLIENTQA-5975 슬랙: https://tmobi.slack.com/archives/C0870L88LV8/p1739425917442339
  const totalCount = rdSearch.data.totalCount;
  const list = rdSearch.data.list || [];
  const hasData = list.length > 0;
  const loaded = rdSearch.loaded;
  const loading = rdSearch.loading;
  const error = rdSearch.error;
  const tmapFamousYn = rdSearch.tmapFamousYn;
  const openNowYn = rdSearch.openNowYn;
  const isWaitingReservation = rdSearch.isWaitingReservation;
  const poiParkYn = rdSearch.poiParkYn;
  const categories = rdSearch.categories;

  const clickedFilter = [
    tmapFamousYn && EFilter.TMAP_FAMOUS,
    openNowYn && EFilter.OPEN_NOW,
    isWaitingReservation && EFilter.WAITING_RESERVATION,
    poiParkYn && EFilter.POI_PARK,
    ...(categories ?? []),
  ].filter(Boolean);

  return {
    rdSearch,
    searchList: rdSearch.searchList,
    isEmpty: loaded && rdSearch.nowPage === 1 && isLastPage && !hasData,
    isError: loaded && error,
    isLoading: loading,
    isLoaded: loaded,
    isShowMoreFetching: hasData && loading,
    isShowMoreChecker: hasData && !isLastPage,
    isShowNotice: hasData && isLastPage,
    isLastPage,
    nowPage: rdSearch.nowPage,
    totalCount,
    needSaveQuery: rdSearch.nowPage === 1 && loaded && list.length > 0,
    clickedFilter,
  };
};

export default useSearchResult;
