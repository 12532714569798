import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useSearchAddress from '../../hooks/useSearchAddress';
import Header from '../Header';
import {EButtonType} from '../../types/Button';
import classNames from 'classnames';
import {EAddressMode, TAddressItem} from '../../types/Search';
import {sendSearchClickLog} from '../../utils/logManager';
import {useAppDispatch, useAppSelector} from '../../ducks/hooks';
import ErrorReload from '../ErrorReload';
import {useOnce} from '../../hooks/useOnce';
import SearchScrollTop from './SearchScrollTop';
import {useSearchPageVisible} from '../../hooks/useSearchPageVisible';
import useCenterTabAlignment from '../../hooks/useCenterTabAlignment';
import SearchRecommendFestivalList from './SearchRecommendFestivalList';
import InView from 'react-intersection-observer';

import s from '../../styles/components/search/SearchFestival.module.scss';
import {useHistory} from 'react-router';
import TMapSender from '@lcc/tmap-inapp';
import SearchFestivalHeaderFilter, {EFilterOption} from './SearchFestivalHeaderFilter';
import {useParseQueryLocation} from 'hooks/useParseQueryLocation';
import Skeleton from 'components/Skeleton';
import {ESkeletonType} from 'types/App';

import actions from 'ducks/actions';
import {fetchFestival, fetchFestivalMore} from 'ducks/festival/slice';
import {EEventHolding, ESortOption} from 'ducks/festival/types';

import ua from 'utils/uaParser';

const AREA_ALL: TAddressItem = {
  areaId: '0',
  areaName: '전체',
  areaDepth1Code: '',
  areaDepth2Code: '',
  areaDepth3Code: '',
};

const SearchFestival = () => {
  const {festival: festivalApi} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const address = useSearchAddress({depth: parseInt(EAddressMode.CATE1, 10), isPlace: true});
  const {showMain} = useSearchPageVisible();
  const {originQueries} = useParseQueryLocation();
  const {tabRef, handleTabActivity} = useCenterTabAlignment();

  const refContent = useRef<HTMLDivElement | null>(null);

  const [initLoad, setInitLoad] = useState(false);
  const [isError, setError] = useState<boolean>();
  const [currentArea, setCurrentArea] = useState(AREA_ALL);

  const areas = useMemo(() => {
    if (address.data) {
      return [AREA_ALL, ...address.data];
    }
  }, [address]);

  const handleClickArea = useCallback(
    (item: TAddressItem, index: number) => {
      if (currentArea.areaId === item.areaId) {
        return;
      }
      sendSearchClickLog('tap.festival_regionfilter', {
        index,
        region_name: item.areaName,
      });
      dispatch(actions.festival.resetList());
      dispatch(fetchFestival({regionDepth1Code: item.areaDepth1Code}));
      refContent.current?.scrollTo(0, 0);
      handleTabActivity(index);
      setCurrentArea(item);
    },
    [currentArea, handleTabActivity, dispatch]
  );

  const handleChangeFilter = useCallback(
    (key: EEventHolding | EFilterOption.ALL) => {
      const newEventHolding = key === EFilterOption.ALL ? undefined : key;
      dispatch(actions.festival.setFestivalEventHolding(newEventHolding));
      dispatch(fetchFestival());
    },
    [dispatch]
  );

  const handleChangeSort = useCallback(
    (newSort: ESortOption) => {
      dispatch(actions.festival.setFestivalSort(newSort));
      dispatch(fetchFestival());
    },
    [dispatch]
  );

  const handelFetchMore = useCallback(() => {
    dispatch(fetchFestivalMore({}));
  }, [dispatch]);

  // 초기 데이터 로드용 파라미터 (초기 지역 설정)
  const initialFetchParams = useMemo(() => {
    const initParams: any = {};
    if (originQueries.tailParam) {
      const tailParamData = JSON.parse((originQueries.tailParam as string) || '{}');
      const targetAreaId = tailParamData.areaId;
      if (targetAreaId) {
        const addressItem = areas?.find((item) => item.areaId === targetAreaId);
        if (addressItem) {
          setCurrentArea(addressItem);
          initParams.regionDepth1Code = addressItem.areaDepth1Code;
        }
      }
    }
    return initParams;
  }, [areas, originQueries.tailParam]);

  // 초기 로드 후 활성 버튼 스크롤
  useOnce(initLoad && !!initialFetchParams.regionDepth1Code, () => {
    const tabEl = tabRef.current as HTMLUListElement;
    const el = document.querySelector(
      `[data-area-id="${initialFetchParams.regionDepth1Code}"]`
    ) as HTMLLIElement;
    if (tabEl && el) {
      tabEl.scrollTo({
        left: el.offsetLeft + el.offsetWidth / 2 - window.innerWidth / 2,
        behavior: 'smooth',
      });
    }
  });

  useOnce(!initLoad && address.loaded, () => {
    dispatch(fetchFestival(initialFetchParams));
  });

  useEffect(() => {
    if (address.loaded && festivalApi.loaded) {
      setInitLoad(true);
    }
  }, [address, festivalApi]);

  useEffect(() => {
    if (address.loaded && festivalApi.loaded) {
      setError(!!(address.error || festivalApi.error));
    }
  }, [address, festivalApi]);

  useEffect(() => {
    if (!showMain) {
      return;
    }
  }, [showMain]);

  useEffect(() => {
    return () => {
      dispatch(actions.festival.reset());
    };
  }, [dispatch]);

  if (isError) {
    return (
      <div className={s.error}>
        <ErrorReload top={0} onReload={() => window.location.reload()} />
      </div>
    );
  }

  return (
    <div className={s.wrap}>
      <div className={s.header}>
        <Header
          leftButton={EButtonType.BACK}
          onGoBack={() => {
            sendSearchClickLog('tap.back');

            if (ua.isInApp) {
              TMapSender.onBackKeyPressed();
            } else {
              history.goBack();
            }
          }}
          title={'가볼만한 축제'}
        />
      </div>
      {address.loaded && (
        <div className={s.container}>
          <ul ref={tabRef} className={s.tab}>
            {areas?.map((item, index) => {
              return (
                <li key={item.areaId} data-area-id={item.areaDepth1Code}>
                  <button
                    type="button"
                    className={classNames(s.item, {
                      [s.active]: item.areaId === currentArea.areaId,
                    })}
                    onClick={() => handleClickArea(item, index)}
                  >
                    {item.areaName}
                  </button>
                </li>
              );
            })}
          </ul>
          <SearchFestivalHeaderFilter
            onFilterChange={handleChangeFilter}
            onSortChange={handleChangeSort}
          />
          <div ref={refContent} className={s.content}>
            <Skeleton
              type={ESkeletonType.FESTIVAL_LIST}
              apiStatus={festivalApi}
              className={s.skeleton_wrap}
            >
              <SearchRecommendFestivalList region={currentArea} isVerticalType />
              {festivalApi.data.currentPage < festivalApi.data.totalPage && (
                <InView
                  onChange={(isVisible) => {
                    isVisible && !festivalApi.loading && handelFetchMore();
                  }}
                  threshold={0.9}
                >
                  <div className={s.visible_dom} />
                </InView>
              )}
              {festivalApi.loaded && (
                <SearchScrollTop data={festivalApi.data.list} wrapper={refContent} />
              )}
            </Skeleton>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFestival;
