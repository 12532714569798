import {getDefaultApiStatus} from 'utils/apis';
import {
  EEventHolding,
  ESortOption,
  TFestivalApiResponse,
  TFestivalData,
  TFestivalItem,
  TFestivalParams,
  TFestivalPayload,
  TFestivalState,
} from './types';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StoreState} from 'ducks/store';
import fetcher from 'utils/fetcher';
import {API_PATH, FULFILLED_STATE, PENDING_STATE, REJECTED_STATE} from 'constant/Api';
import {CENTER_WGS84} from 'constant/Map';

const ACTION_PREFIX = 'festival';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 50;

const initialState: TFestivalState = {
  ...getDefaultApiStatus<TFestivalData>({
    list: [] as TFestivalItem[],
    currentPage: 0,
    totalPage: 0,
    totalCount: 0,
  }),
  sort: ESortOption.POPULARITY,
  eventHolding: undefined,
  lastParam: undefined,
};

export const fetchFestival = createAsyncThunk<TFestivalPayload, TFestivalParams | undefined>(
  `${ACTION_PREFIX}/festival`,
  async (props = {}, creator) => {
    const storeState = creator.getState() as StoreState;
    const {lastParam = {}, sort, eventHolding} = storeState.festival;
    const params: TFestivalParams = {
      ...lastParam,
      sort,
      eventHolding,
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      ...props,
    };

    if (params.sort === ESortOption.DISTANCE) {
      params.lat = storeState.map.userPosition?.lat ?? CENTER_WGS84.lat;
      params.lon = storeState.map.userPosition?.lon ?? CENTER_WGS84.lon;
    }

    creator.dispatch(festivalSlice.actions.resetList());
    creator.dispatch(festivalSlice.actions.setFestivalParam(params));

    const response = await fetcher.get(API_PATH.GET_SEARCH_FESTIVAL, {params});
    return response.data;
  }
);

export const fetchFestivalMore = createAsyncThunk<TFestivalPayload, TFestivalParams | undefined>(
  `${ACTION_PREFIX}/festivalMore`,
  async (props = {}, creator) => {
    const storeState = creator.getState() as StoreState;
    const {data, lastParam, sort, eventHolding} = storeState.festival;
    const {currentPage, totalPage} = data;

    if (!lastParam || currentPage >= totalPage) {
      return;
    }

    const params: TFestivalParams = {
      ...lastParam,
      sort,
      eventHolding,
      page: currentPage + 1,
      ...props,
    };

    if (params.sort === ESortOption.DISTANCE) {
      params.lat = storeState.map.userPosition?.lat ?? CENTER_WGS84.lat;
      params.lon = storeState.map.userPosition?.lon ?? CENTER_WGS84.lon;
    }

    creator.dispatch(festivalSlice.actions.setFestivalParam(params));

    const response = await fetcher.get(API_PATH.GET_SEARCH_FESTIVAL, {params});
    return response.data;
  }
);

const getPendingState = (state: TFestivalState) => {
  state.loading = PENDING_STATE.loading;
  state.loaded = PENDING_STATE.loaded;
  state.error = undefined;
};

const getFulfilledState = (state: TFestivalState, action) => {
  const {festivalInfos, currentPage, totalPage, totalCount} = action.payload
    .data as TFestivalApiResponse;
  state.loading = FULFILLED_STATE.loading;
  state.loaded = FULFILLED_STATE.loaded;
  state.error = FULFILLED_STATE.error;
  state.data.list = [...state.data.list, ...festivalInfos];
  state.data.currentPage = currentPage;
  state.data.totalPage = totalPage;
  state.data.totalCount = totalCount;
};

const getRejectedState = (state: TFestivalState, action) => {
  state.loading = REJECTED_STATE.loading;
  state.loaded = REJECTED_STATE.loaded;
  state.error = action.error;
};

const festivalSlice = createSlice({
  name: 'festival',
  initialState,
  reducers: {
    reset: (state) => {
      state.loaded = initialState.loaded;
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.sort = initialState.sort;
      state.eventHolding = initialState.eventHolding;
      state.lastParam = initialState.lastParam;
    },
    resetList: (state) => {
      state.data = initialState.data;
    },
    setFestivalParam: (state, action: PayloadAction<TFestivalParams>) => {
      state.lastParam = action.payload;
    },
    setFestivalSort: (state, action: PayloadAction<ESortOption>) => {
      state.sort = action.payload;
    },
    setFestivalEventHolding: (state, action: PayloadAction<EEventHolding | undefined>) => {
      state.eventHolding = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFestival.pending, getPendingState)
      .addCase(fetchFestival.fulfilled, getFulfilledState)
      .addCase(fetchFestival.rejected, getRejectedState)

      .addCase(fetchFestivalMore.pending, getPendingState)
      .addCase(fetchFestivalMore.fulfilled, getFulfilledState)
      .addCase(fetchFestivalMore.rejected, getRejectedState);
  },
});

export default festivalSlice;
