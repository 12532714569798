import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import semver from 'semver';
import TMapSender from '@lcc/tmap-inapp';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';
import {VSMInterfaceProvider} from 'context/VSMInterfaceContext';
import {Paths} from 'constant/RoutePath';
import {StorageKey} from 'constant/Storage';
import {EActionId} from 'constant/Log';
import ua from 'utils/uaParser';
import * as TMapInApp from 'utils/tmapInApp';
import {EListMode, TListDrawerResult} from 'types/ListDrawer';
import {getAllParams} from 'utils/apis';
import {generateUrl, moveToUrl} from 'utils/url';
import useUserData from 'hooks/useUserData';
import useLogger from 'hooks/useLogger';
import useMapOffset from 'hooks/useMapOffset';
import {filterQueryToCategoryParam} from 'hooks/useValidCategory';
import {useOnce} from 'hooks/useOnce';
import {useRemoteConfig} from 'hooks/useRemoteConfig';
import {getValidLonLat} from 'utils/map';
import {HistoryBackLink} from 'components/HistoryBackLink';
import {CENTER_WGS84} from 'constant/Map';
import PlaceVerticalPopup from 'components/place/PlaceVerticalPopup';
import {PLACE_CONFIG} from 'constant/Firebase';
import PlaceTagList from 'components/PlaceTagList';
import DrawerContainer from 'components/DrawerContainer';
import PlaceMap from 'components/PlaceMap';
import VSMCompass from 'components/VSMCompass';
import BuildInfo from 'components/BuildInfo';
import {
  TNOW_CATEGORY_AREA_HEIGHT,
  TNOW_CENTER_POINT_MIN_HEIGHT,
  TNOW_LIST_HANDLE_HEIGHT,
  TNOW_CENTER_MAP_RATIO,
  DEFAULT_HANDLE_BORDER_RADIUS,
  DEFAULT_HANDLE_SIZE,
  MAX_MARKER_WIDTH,
  MAX_MARKER_HEIGHT,
  MAX_MARKER_TITLE_HEIGHT,
} from 'constant/Size';
import {ReactComponent as IconArrow} from 'resource/images/@tmds_element/ico_arrow_left.svg';

import {ETPlaceTab} from 'ducks/tplacehome/types';
import TPlaceTab from 'components/tplacehome/TPlaceTab';
import TPlaceMain from 'components/tplacehome/TPlaceMain';

import s from 'styles/pages/PlaceMainHomePage.module.scss';
import {fetchAroundMeList, fetchRegionListDepth1} from 'ducks/tplacehome/slice';
import classNames from 'classnames';
import {TPlaceItem} from 'ducks/place/types';
import {isEmpty} from 'utils/lodash';
import {EAppRequestMode} from 'types/App';
import usePlaceHome from 'hooks/usePlaceHome';
import {useParamLog} from 'hooks/useParamLog';
import {getCurrentPackageVersion} from 'utils/tmapUtils';
// import BuildInfoTempZoom from 'components/BuildInfoTempZoom';
import useReverseGeocoding from 'hooks/useReverseGeocoding';
import StarBucksEventButton from 'components/tplacehome/shared/EventStarbucks2025';
import {WeatherProvider} from 'context/WeatherContext';

const DEFAULT_PLACEHOLDER_KEYWORD = '맛집, 카페, 음식 검색';

const PlaceHomePage = () => {
  const dispatch = useAppDispatch();
  const {sendClickLogWithMapView} = useLogger();
  const {map, userInfo, tplacehome, layout, userInteraction, remote, log} = useAppSelector(
    (state) => state
  );

  const history = useHistory();
  const params = useMemo(() => getAllParams(), []);
  const placehomeHook = usePlaceHome();
  const [pageReady, setPageReady] = useState(false);
  const initialDrawerListMode = useRef(EListMode.TOP);

  const {centerOffset, getBoundsPaddingAndOffset} = useMapOffset({
    offsetTop: TNOW_CATEGORY_AREA_HEIGHT,
  });
  const isStandAloneView = useMemo(() => params.tailParam.stand_alone_view === 1, [params]);
  const placeHolderKeyword = useMemo(() => {
    const placeholderInfo = remote.tnowPlaceholder;

    if (!placeholderInfo) {
      return null;
    }

    if (placeholderInfo.screen !== 'tnow') {
      return null;
    }

    return placeholderInfo.text;
  }, [remote.tnowPlaceholder]);

  useUserData({ignorePosition: true});
  useRemoteConfig(PLACE_CONFIG);

  // preprocess1 - 페이지 진입 직후 템플릿 렌더 전에 반드시 처리해야 할 것들. 리모트 컨피그 로드완료 후
  useOnce(!!remote.lastUpdateTime, () => {
    // list모드 AB테스트
    switch (remote.ui_ab_type) {
      case 'A':
        initialDrawerListMode.current = EListMode.TOP;
        break;
      case 'B':
        initialDrawerListMode.current = EListMode.CENTER;
        break;
      case 'C':
      default:
        initialDrawerListMode.current = EListMode.TOP;
    }
    dispatch(actions.userInteraction.setInteraction({drawerMode: initialDrawerListMode.current}));
    setPageReady(true);
  });

  // preprocess2 - 템플릿 렌더 후 비동기 처리들.
  useOnce(pageReady && userInfo.userKey && userInfo.sessionId, async () => {
    // redirect - 서브페이지, category 경로로 들어온 경우 redirect
    const deepLinkTarget = filterQueryToCategoryParam(params);
    if (deepLinkTarget?.type) {
      sendClickLogWithMapView(EActionId.DEEPLINK_OPEN, {
        category: params.category,
        reqKey: params.reqKey,
      });

      const targetPath = generateUrl(Paths.PlaceCategory, {
        ...deepLinkTarget,
        tailParam: JSON.stringify(params?.tailParam || {}),
      });

      history.replace(Paths.PlaceMain);
      moveToUrl(window.location.origin + targetPath);

      // aos 에서 deeplink가 올때마다 티지금 탭에 새로운 페이지로 history를 쌓고 있어서 history.back으로 돌아가줌
      // aos 가 기본쪽으로 0페이지에 파라미터 없는 '/app/place/main' 을 가지고 있음
      // ios 의 경우 백버튼이 없기에 상관 없지만 정상 동작하는것 확인 됨
      // replace는 혹시나 0번째 화면이라 back 이 없을때 현재 페이지를 초기화해서 새로고치도록 적용
      // 10.11.0 미만 버전에서만 적용
      if (ua.isInApp && ua.isAndroid && semver.lt(ua.tmapAppVersion, '10.11.0')) {
        window.history.back();
        return;
      }
    }

    // preload data. 페이지 실행전에 필요한 데이터 로드
    const paramLonLat = getValidLonLat({lon: params.centerLon, lat: params.centerLat});
    const mapPitch = params.tailParam.mapPitch;
    const mapBearing = params.tailParam.mapBearing;

    mapPitch && dispatch(actions.map.setPitch(mapPitch));
    mapBearing && dispatch(actions.map.setPitch(mapBearing));

    try {
      const position = await TMapInApp.getLastPosition();
      const mapCenter = paramLonLat || position;
      dispatch(actions.map.setUserPosition(position));
      dispatch(actions.map.setNowCenter(mapCenter));
    } catch {
      const mapCenter = paramLonLat || CENTER_WGS84;
      dispatch(actions.map.setUserPosition(CENTER_WGS84));
      dispatch(actions.map.setNowCenter(mapCenter));
    }

    // 1뎁스 리스트 (다른지역 리스트 노출을 위함. 비동기여도 무관.)
    dispatch(fetchRegionListDepth1({}));

    dispatch(actions.userInteraction.setDrawerRefreshTailText('에서 재탐색'));
    dispatch(actions.tplacehome.setInitialDataLoaded());
  });

  const drawerCenterPoint = useMemo(
    () =>
      Math.max(
        parseInt(`${layout.appSize.displayHeight * TNOW_CENTER_MAP_RATIO}`, 10),
        TNOW_CENTER_POINT_MIN_HEIGHT
      ),
    [layout.appSize.displayHeight]
  );

  const handleRefresh = useCallback(
    (position) => {
      dispatch(fetchAroundMeList({destLat: position.lat, destLon: position.lon}));
    },
    [dispatch]
  );

  // 맵용 list. flicking과 동시에 변경되면 버벅임. 약간의 delay를 주기 위함
  const mapListInitalFlagRef = useRef(false);
  const [mapList, setMapList] = useState<TPlaceItem[]>([]);
  useEffect(() => {
    const delay = 500;
    if (tplacehome.currentTab === ETPlaceTab.AROUND_ME) {
      const newList = tplacehome.aroundMeData.result.data.filteredList;
      if (isEmpty(newList) && !mapListInitalFlagRef.current) {
        return;
      }
      if (mapListInitalFlagRef.current) {
        setTimeout(() => setMapList(tplacehome.aroundMeData.result.data.filteredList), delay);
      } else {
        setMapList(tplacehome.aroundMeData.result.data.filteredList);
        mapListInitalFlagRef.current = true;
      }
    } else {
      setTimeout(() => setMapList([]), delay);
    }
  }, [tplacehome.aroundMeData.result.data.filteredList, tplacehome.currentTab]);

  // 드로워가 최상단에 위치했는지 판단 (shadown 표현을 위함)
  const [isTopPosition, setIsTopPosition] = useState(true);
  const showTopShadow = useMemo(
    () => isTopPosition && !layout.appSize.isLandscape && !userInteraction.calloutInfo,
    [isTopPosition, layout.appSize.isLandscape, userInteraction.calloutInfo]
  );

  // 어디갈까를 이탈하고(다른탭 이동) 5분이 지나고 되돌아오면 reset 한다.
  // android에서 location.href로 다른 서비스를 호출할때 onPause가 호출되지 않음. blur 이벤트로 보완
  const bodyBlurCallback = useCallback(
    () => dispatch(actions.userInteraction.setPauseKey(Date.now())),
    [dispatch]
  );
  useEffect(() => {
    window.addEventListener('blur', bodyBlurCallback);
    return () => window.removeEventListener('blur', bodyBlurCallback);
  }, [bodyBlurCallback]);
  // 초기 세팅
  useEffect(() => {
    const now = Date.now();
    dispatch(actions.userInteraction.setPauseKey(now));
    dispatch(actions.userInteraction.setResumeKey(now));
  }, [dispatch]);
  useEffect(() => {
    const resumeKey = userInteraction.resumeKey || 0;
    const pauseKey = userInteraction.pauseKey || 0;
    const resetDelay = 1000 * 60 * 5; // 5분
    if (resumeKey > pauseKey + resetDelay) {
      window.location.reload();
    }
  }, [dispatch, userInteraction.pauseKey, userInteraction.resumeKey]);

  /**
   * log 관련
   */
  useOnce(userInfo.userKey && userInfo.sessionId, () => placehomeHook.logInit());
  // tab expose
  const {referrer} = useParamLog();
  // 사용자 위치 geo정보 로드
  const userReverseGeoInfo = useReverseGeocoding({
    lat: map.userPosition?.lat,
    lon: map.userPosition?.lon,
  });
  // 유저위치 reserverGeo update
  useEffect(() => {
    dispatch(
      actions.map.setUserPositionReverseGeoInfo({
        regionName1: userReverseGeoInfo.data?.regionName1 || '',
        regionName2: userReverseGeoInfo.data?.regionName2 || '',
        regionName3: userReverseGeoInfo.data?.regionName3 || '',
        regionCode: userReverseGeoInfo.data?.legalDongCode || '',
      })
    );
  }, [dispatch, userReverseGeoInfo]);
  useEffect(() => {
    if (log.isInitialize && userReverseGeoInfo.loaded) {
      placehomeHook.updatePageId();
      const custom = {
        referrer: referrer, // 확인필요
        mapview: placehomeHook.getDrawerModeIndex(userInteraction.drawerMode),
        package_version: getCurrentPackageVersion(),
        region_1D: userReverseGeoInfo.data?.regionName1,
        region_2D: userReverseGeoInfo.data?.regionName2,
        region_3D: userReverseGeoInfo.data?.regionName3,
        // ab테스트 임시코드로, 해당 테스트 종료시 제거 할 것
        abt_value: remote.ui_ab_type || '',
        abt_name: remote.ui_ab_type ? 'drawer_ab_test' : '',
      };
      placehomeHook.sendExpose(custom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log.isInitialize, tplacehome.currentTab, userReverseGeoInfo.loaded]);

  /**
   * render
   */
  if (!pageReady) {
    return null;
  }
  return (
    <VSMInterfaceProvider>
      <WeatherProvider>
        <div
          className={classNames(s.header, isStandAloneView && s.stand_alone_view)}
          style={{top: layout.appSize.statusBarHeight}}
        >
          <button
            className={s.search_button}
            onClick={() => {
              placehomeHook.sendEvent('tap.search_field');
              TMapSender.showSearch({
                keyword: '',
                extra: JSON.stringify({reqMode: EAppRequestMode.TPLACE_HOME}),
              });
            }}
          >
            <div className={s.content}>{placeHolderKeyword || DEFAULT_PLACEHOLDER_KEYWORD}</div>
          </button>
          <HistoryBackLink className={s.back}>
            <IconArrow />
          </HistoryBackLink>
          <PlaceTagList />
          <div className={classNames(s.top_shadow, {[s.show]: showTopShadow})} />
          {tplacehome.initialDataLoaded && <VSMCompass className={s.vsm_compass} />}
        </div>

        <DrawerContainer
          list={[]}
          listMode={userInteraction.drawerMode}
          listTopAreaPadding={70}
          tooltipStorageKey={StorageKey.TNOW_TOOLTIP}
          onChangeListMode={(drawerMode) => {
            dispatch(actions.userInteraction.setInteraction({drawerMode}));
          }}
          mapComponent={(drawerProps: TListDrawerResult) => {
            const {boundOffset, boundsPadding} = getBoundsPaddingAndOffset(drawerProps, {
              headerHeight: 130,
              maxMarkerHeight: 0,
              maxMarkerWidth: MAX_MARKER_WIDTH,
              maxMarkerTitleHeight: MAX_MARKER_TITLE_HEIGHT + (ua.isAndroid ? 20 : 0),
            });

            return tplacehome.initialDataLoaded && map.userPosition && boundsPadding ? (
              <PlaceMap
                initPitch={map.nowPitch}
                initBearing={map.nowBearing}
                initLoad={tplacehome.initialDataLoaded}
                defaultCenter={map.userPosition}
                priorityBounds={{top: MAX_MARKER_HEIGHT}}
                boundsPadding={boundsPadding}
                list={mapList}
                markerClickToCenter={true}
                centerOffset={centerOffset}
                boundOffset={boundOffset}
                useUserPositionBound={true}
                personMarkerLayerVisible={true}
              />
            ) : (
              <></>
            );
          }}
          drawerOptions={{
            centerHeight: drawerCenterPoint,
            topAreaHeight: TNOW_CATEGORY_AREA_HEIGHT,
            listHandleHeight: TNOW_LIST_HANDLE_HEIGHT,
          }}
          statusBarHeight={layout.appSize.statusBarHeight}
          handleBorderRadius={layout.appSize.isLandscape ? 0 : DEFAULT_HANDLE_BORDER_RADIUS}
          handleSize={
            layout.appSize.isLandscape
              ? layout.appSize.statusBarHeight + DEFAULT_HANDLE_SIZE
              : DEFAULT_HANDLE_SIZE
          }
          listHeaderComponent={<TPlaceTab />}
          refreshButtonVisible={tplacehome.currentTab === ETPlaceTab.AROUND_ME}
          onRefresh={handleRefresh}
          listComponent={null}
          fixedContentMode={true}
          fixedContent={<TPlaceMain key={tplacehome.refreshKey} />}
          onChangeBottomPadding={(value) => {
            dispatch(actions.tplacehome.setContentBottomPadding(value));
          }}
          onChangePositionMode={(values) => {
            setIsTopPosition(values.isTop);
          }}
          isHideToTop={true}
          saveToggleButtonVisible={true}
          weatherButtonVisible={true}
        />
        <BuildInfo />
        {/* 스타벅스 2025 이벤트 임시 */}
        <StarBucksEventButton />
        {/* 저장 확인용 임시 */}
        {/* <BuildInfoTempZoom /> */}
        {tplacehome.initialDataLoaded && <PlaceVerticalPopup />}
      </WeatherProvider>
    </VSMInterfaceProvider>
  );
};

export default PlaceHomePage;
