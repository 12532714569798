import Thumbnail from 'components/Thumbnail';
import {TFestivalItem} from 'ducks/festival/types';
import useMoveToTarget from 'hooks/useMoveToTarget';
import {useCallback} from 'react';
import s from 'styles/components/ranking/RankingFestivalSection.module.scss';
import {EImageSize} from 'types/Image';
import {setComma} from 'utils/formatter';
import {getImageUrl} from 'utils/url';

type TProps = {
  itemData: TFestivalItem;
  onClick?: VoidFunction;
};

const RankingFestivalSectionItem = (props: TProps) => {
  const itemData = props.itemData;
  const {moveToDetail} = useMoveToTarget();

  const handleClickPoi = useCallback(() => {
    props.onClick?.();
    moveToDetail(props.itemData);
  }, [moveToDetail, props]);

  return (
    <div className={s.item_wrap}>
      <button className={s.content} onClick={handleClickPoi}>
        <div className={s.thumb}>
          <Thumbnail
            imgSrc={getImageUrl(itemData.imageUrl || '', EImageSize.THUMBNAIL)}
            alt={`${itemData.name} 이미지`}
            className={s.img}
          />
          {itemData.isRunning && <div className={s.tag}>진행중</div>}
        </div>
        <div className={s.info_wrap}>
          <span className={s.name}>{itemData.name}</span>
          <span className={s.date}>{itemData.date}</span>
          <span className={s.region}>
            <span>
              {itemData.areaDepth1Name} {itemData.areaDepth2Name}
            </span>
            {(itemData.goingCount || 0) >= 5 && (
              <>
                <span className={s.dot}>·</span>
                <span className={s.visit_count}>
                  <em>{setComma(itemData.goingCount)}</em>대 가는중
                </span>
              </>
            )}
          </span>
        </div>
      </button>
    </div>
  );
};

export default RankingFestivalSectionItem;
