import classNames from 'classnames';
// import {IcoReload} from 'components/@tmds/icons/IcoReload';
import usePlaceHome from 'hooks/usePlaceHome';
import {CSSProperties, useCallback} from 'react';
import InView from 'react-intersection-observer';
import s from 'styles/components/tplacehome/TPlaceDiscoveryMoreButton.module.scss';

type TProps = {
  total: number | string;
  current: number | string;
  onClick?: VoidFunction;
  className?: string;
  moduleName?: string;
  style?: CSSProperties;
};

const MoreButton = (props: TProps) => {
  const placehomeHook = usePlaceHome();

  const onClickButton = useCallback(() => {
    if (props.moduleName) {
      placehomeHook.sendEventDiscovery('tap.seemore', {
        module: props.moduleName,
        current_page: props.current,
        max_page: props.total,
      });
    }
    props.onClick?.();
  }, [placehomeHook, props]);

  return (
    <InView
      as="div"
      className={classNames(s.wrap, props.className)}
      onChange={(inView) =>
        inView &&
        placehomeHook.sendEventDiscovery('view.seemore', {
          module: props.moduleName,
        })
      }
      style={props.style}
    >
      <button onClick={onClickButton}>
        {/* <IcoReload className={s.icon} width={20} height={18} /> */}
        <i className={s.icon}>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.4097 3.00539L15.4225 6.32671C15.423 6.46676 15.3676 6.60122 15.2685 6.70019C15.1694 6.79916 15.0349 6.85444 14.8949 6.85373L11.5768 6.83708L11.5821 5.78709L14.3705 5.80109L14.3597 3.00944L15.4097 3.00539Z"
              fill="#36393D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.35156 9.00039C3.35156 5.5589 6.16034 2.77539 9.61726 2.77539C11.8868 2.77539 13.8754 3.97475 14.9743 5.77017L15.2484 6.21795L14.3529 6.76611L14.0788 6.31833C13.1639 4.82369 11.5086 3.82539 9.61726 3.82539C6.73318 3.82539 4.40156 6.14583 4.40156 9.00039C4.40156 11.8549 6.73318 14.1754 9.61726 14.1754C11.4676 14.1754 13.0921 13.2199 14.0183 11.7788L14.3022 11.3372L15.1855 11.9049L14.9016 12.3466C13.7891 14.0774 11.8376 15.2254 9.61726 15.2254C6.16034 15.2254 3.35156 12.4419 3.35156 9.00039Z"
              fill="#36393D"
            />
          </svg>
        </i>
        <span className={s.text}>더보기</span>
        <span className={s.page_num}>
          <em>{props.current}</em>/{props.total}
        </span>
      </button>
    </InView>
  );
};

export default MoreButton;
