import {useCallback, useEffect, useMemo, useState, Fragment} from 'react';
import InView from 'react-intersection-observer';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';
import useFavorite from 'hooks/useFavorite';

import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import classNames from 'classnames';
import Thumbnail from 'components/Thumbnail';
import DiscoveryPoiItemRow from './DiscoveryPoiItemRow';
import MoreButton from './MoreButton';
import {IcoArrowRight} from 'components/@tmds/icons/IcoArrowRight';
// import {IcDotBetween} from 'components/@tmds/icons/v1.2/IcDotBetween';
import {IcArrowDownBold} from 'components/@tmds/icons/v1.2/IcArrowDownBold';
import {FRONTMAN_API_PATH} from 'constant/Api';
import {frontmanFetcher} from 'utils/fetcher';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import CustomSelectLayer from 'components/CustomSelectLayer';
import useDimmedQuery from 'hooks/useDimmedQuery';
import compact from 'lodash/compact';
import {isEmpty, shuffle} from 'utils/lodash';
import DiscoveryWeekendSkeleton from './DiscoveryWeekendSkeleton';
import {ELoadState, TDiscoveryContentProps} from '../types';
import DiscoveryDividerIcon from './comps/DividerIcon';
import {setTimeEvent} from 'utils/mixPanel';

// 서울,경기,인천,강원,대전,충남,충북,세종,경남,경북,부산,울산,대구,전남,전북,광주,제주
const regionGroupData = {
  group1: ['서울', '경기', '인천', '강원'],
  group2: ['전남', '전북', '광주'],
  group3: ['대전', '충남', '충북', '세종'],
  group4: ['경남', '경북', '부산', '울산', '대구'],
  group5: ['제주'],
};

const findRegionGroupId = (regionName: string) => {
  let groupId = '';
  Object.keys(regionGroupData).forEach((id) => {
    const itemList = regionGroupData[id];
    if (itemList.includes(regionName)) {
      groupId = id;
    }
  });
  return groupId;
};

const DiscoveryWeekend = (props: TDiscoveryContentProps) => {
  const {tplacehome, userInfo, map} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const itemCount = 5;
  const [loadState, setLoadState] = useState(ELoadState.READY);
  const [visitedPlace, setVisitedPlace] = useState<any>(null);
  const [poiList, setPoiList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRegionGroup, setSelectedRegionGroup] = useState('');

  const currentList = useMemo(() => {
    const currentCount = currentPage * itemCount;
    return poiList && poiList.slice(currentCount, currentCount + itemCount);
  }, [currentPage, poiList]);

  const totalPages = useMemo(() => {
    return Math.ceil((poiList?.length || 0) / itemCount);
  }, [poiList?.length]);

  const {checkIsFavorite} = useFavorite({list: currentList || []});

  const regionItemPopupQuery = useDimmedQuery({id: 'REGION_SELECT'});

  const recommendedText = useMemo(() => {
    switch (visitedPlace?.reasonType) {
      case 'TRIP':
      case 'PLACE_DETAIL':
        return '최근 주말에 방문/탐색한';
      case 'POPULAR':
      default:
        return '최근 주말에 길 안내 많이 받은';
    }
  }, [visitedPlace?.reasonType]);

  const recommendedTextTail = useMemo(() => {
    switch (visitedPlace?.reasonType) {
      case 'TRIP':
      case 'PLACE_DETAIL':
        return '기반으로 추천된 곳이에요.';
      case 'POPULAR':
      default:
        return '기반으로 추천된 곳이에요.';
    }
  }, [visitedPlace?.reasonType]);

  const regionOptionList = useMemo(() => {
    return Object.keys(regionGroupData).map((id) => {
      return {
        id,
        label: regionGroupData[id].join(', '),
      };
    });
  }, []);

  const computedSelectedRegionOptionItem = useMemo(() => {
    if (selectedRegionGroup) {
      return regionOptionList.find((item) => item.id === selectedRegionGroup);
    }
    const userRegionName = tplacehome.discoveryData.userRegionInfo?.region1Name;
    const userRegionGroup = findRegionGroupId(userRegionName || '');
    if (userRegionGroup) {
      return regionOptionList.find((item) => item.id === userRegionGroup);
    }
    return regionOptionList[0];
  }, [regionOptionList, selectedRegionGroup, tplacehome.discoveryData.userRegionInfo?.region1Name]);

  const currentRegionNameList = useMemo(() => {
    if (!computedSelectedRegionOptionItem) {
      return [];
    }
    return regionGroupData[computedSelectedRegionOptionItem.id] || [];
  }, [computedSelectedRegionOptionItem]);

  const fetchData = useCallback(async () => {
    setLoadState(ELoadState.LOADING);
    try {
      const params = {
        regionName1: currentRegionNameList[0] || '',
        rows: 25,
        userLat: map.userPosition?.lat,
        userLon: map.userPosition?.lon,
      };
      const res = await frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_WEEKEND_LIST_V2, {
        params,
      });

      setVisitedPlace(res.data.data.visitedPlace);
      setPoiList(shuffle(compact(res.data.data.recommendList))); // null제거, 랜덤섞기
      setCurrentPage(0);
      setLoadState(ELoadState.LOADED);
    } catch (e) {
      setLoadState(ELoadState.ERROR);
    }
  }, [currentRegionNameList, map.userPosition?.lat, map.userPosition?.lon]);

  useEffect(() => {
    if (props.rootInitLoaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedSelectedRegionOptionItem, props.rootInitLoaded]);

  const handleClickBasePoi = useCallback(() => {
    placehomeHook.sendEventDiscovery('tap.weekend_main_poi', {
      poi_id: visitedPlace?.poiId,
      poi_name: visitedPlace.poiName,
      euk: userInfo.euk,
      selected_region: computedSelectedRegionOptionItem?.label,
    });
    placehomeHook.openPoiDetail({poiId: visitedPlace?.poiId});
  }, [
    computedSelectedRegionOptionItem?.label,
    placehomeHook,
    userInfo.euk,
    visitedPlace?.poiId,
    visitedPlace?.poiName,
  ]);

  const onClickMoreButton = useCallback(() => {
    let nextPage = currentPage + 1;
    if (nextPage === totalPages) {
      nextPage = 0;
    }

    setCurrentPage(nextPage);
    props.scrollToEl?.('[data-name=discovery_weekend_content]');
  }, [currentPage, props, totalPages]);

  const handleRegionSelect = useCallback(
    (optionItem) => {
      placehomeHook.sendEventDiscovery('tap.weekend_region_update', {
        updated_region: optionItem.label,
        previous_region: computedSelectedRegionOptionItem?.label,
      });
      setSelectedRegionGroup(optionItem.id);
      regionItemPopupQuery.close();
      props.scrollToEl?.('[data-name=discovery_weekend]');
    },
    [computedSelectedRegionOptionItem?.label, placehomeHook, props, regionItemPopupQuery]
  );

  const [wrapInView, setWrapInView] = useState(false);
  useEffect(() => {
    if (wrapInView && loadState === ELoadState.LOADED) {
      setTimeEvent('tap.weekend_outing_poi'); // poi click time event
      placehomeHook.sendEventDiscovery('view.weekend_outing', {
        list_num: poiList.length,
        type: visitedPlace.reasonType,
        ...props.logParam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapInView, loadState]);

  const isLoading = useMemo(() => {
    return [ELoadState.LOADING, ELoadState.READY].includes(loadState);
  }, [loadState]);

  const isHideState = useMemo(() => {
    const loadError = ELoadState.ERROR === loadState;
    const countError =
      ELoadState.LOADED === loadState && (isEmpty(poiList) || poiList.length < itemCount);
    return loadError || countError;
  }, [poiList, loadState]);

  if (isHideState) {
    return null;
  }

  return (
    <InView
      as="section"
      className={classNames(s.section, s.section_weekend)}
      onChange={setWrapInView}
      data-name="discovery_weekend"
    >
      <header className={s.header} style={{marginBottom: '20px'}}>
        <h1 className={s.title}>
          <span className={s.sub}>이번 주말</span>
          <em className={s.main}>가볼만한 장소</em>
        </h1>
        <InView
          as="button"
          className={s.region_select_button}
          onClick={() => {
            placehomeHook.sendEventDiscovery('tap.weekend_region', {
              selected_region: computedSelectedRegionOptionItem?.label,
            });
            regionItemPopupQuery.open();
          }}
          onChange={(isView) =>
            isView &&
            placehomeHook.sendEventDiscovery('view.weekend_region', {
              selected_region: computedSelectedRegionOptionItem?.label,
            })
          }
        >
          <div className={s.name_wrap}>
            {currentRegionNameList.map((name, index) => (
              <Fragment key={index}>
                <span className={s.item}>{name}</span>
                {index < currentRegionNameList.length - 1 && (
                  <DiscoveryDividerIcon color="#BEC5CC" />
                )}
              </Fragment>
            ))}
          </div>
          <IcArrowDownBold width={14} height={14} color="tmobiBlue600" className={s.ico_arrow} />
        </InView>
      </header>
      {isLoading || false ? (
        <DiscoveryWeekendSkeleton />
      ) : (
        <>
          <InView
            as="div"
            className={s.base_poi}
            onChange={(isView) =>
              isView &&
              placehomeHook.sendEventDiscovery('view.weekend_main_poi', {
                poi_id: visitedPlace.poiId,
                poi_name: visitedPlace.poiName,
              })
            }
          >
            <div className={s.base_poi_cont} onClick={handleClickBasePoi}>
              <div className={s.title_wrap}>
                <p className={s.sub_title}>{recommendedText}</p>
                <h2 className={s.title}>
                  <span className={s.poi_name}>{visitedPlace?.poiName}</span>
                  <IcoArrowRight color="gray300" width={14} height={14} className={s.icon} />
                </h2>
                <p className={s.desc}>{recommendedTextTail}</p>
              </div>
              <div className={s.img_wrap}>
                <Thumbnail
                  imgSrc={getImageUrl(visitedPlace.imageUrl, EImageSize.THUMBNAIL)}
                  alt=""
                  className={s.bg_img}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </InView>
          <div className={classNames(s.rounded_content_wrap)} data-name="discovery_weekend_content">
            <ul className={s.list_wrap}>
              {currentList.map((item, index) => {
                const itemLogParams = {
                  main_poi_id: visitedPlace.poiId,
                  pkey: item.pkey,
                  poi_id: item.poiId,
                  poi_name: item.poiName,
                  poi_cate: item.svcCategoryName,
                  poi_region_1D: item.regionName1,
                  poi_region_2D: item.regionName2,
                  guide_count: item.visitCount3Month || null,
                  review_count: item.reviewDto.reviewCount || null,
                  avg_rating: item.reviewDto.reviewGrade || null,
                  selected_region: computedSelectedRegionOptionItem?.label,
                  tag: item.tags,
                };
                return (
                  <InView
                    as="li"
                    key={`${item.poiId}_${index}`}
                    className={s.cell}
                    onChange={(inView) =>
                      inView &&
                      placehomeHook.sendEventDiscovery('view.weekend_outing_poi', itemLogParams)
                    }
                  >
                    <DiscoveryPoiItemRow
                      key={item.poiId}
                      data={item}
                      isFavorite={checkIsFavorite(item)}
                      onClickDetail={() =>
                        placehomeHook.sendEventDiscovery('tap.weekend_outing_poi', {
                          euk: userInfo.euk,
                          region_1D: map.userPositionReverseGeoInfo?.regionName1,
                          region_2D: map.userPositionReverseGeoInfo?.regionName2,
                          region_3D: map.userPositionReverseGeoInfo?.regionName3,
                          ...itemLogParams,
                        })
                      }
                      moduleName="WEEKEND"
                      itemIndex={currentPage * itemCount + index}
                    />
                  </InView>
                );
              })}
            </ul>
            {totalPages > 1 && (
              <MoreButton
                current={currentPage + 1}
                total={totalPages}
                onClick={onClickMoreButton}
                moduleName="WEEKEND"
                style={{marginTop: '30px'}}
              />
            )}
          </div>
        </>
      )}

      <CustomSelectLayer
        isOpen={regionItemPopupQuery.isOpen}
        options={regionOptionList || []}
        selectedItem={computedSelectedRegionOptionItem}
        onClickItem={handleRegionSelect}
      />
    </InView>
  );
};

export default DiscoveryWeekend;
