/**
 * useCategoryRedDot 과 합침
  // itemCreateDateTime < 현재시각 < expiredDateTime
  // 1. itemReadDateTime 이 없는 경우 : new
  // 2. itemReadDateTime < itemCreateDateTime : new
*/

import {useCallback, useMemo} from 'react';
import TMapSender, {
  ECallbackKeys,
  EDateFormat,
  ERedDotDisplayType,
  ERedDotItemGroup,
  TMapReceiver,
  TRedDotItem,
} from '@lcc/tmap-inapp';
import {getSafeDateFormat} from '../utils/date';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import actions from 'ducks/actions';

type TProps = {
  itemGroup: ERedDotItemGroup;
};

export const isUnReadRedDot = (item: TRedDotItem) => {
  const now = Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT) || 0);
  const isNotRead = !item.readDateTime;
  const isInTime = Number(item.startDateTime || 0) < now && now < Number(item.expiredDateTime || 0);

  return isNotRead && isInTime;
};

const useRedDot = ({itemGroup}: TProps) => {
  const userInfo = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();
  const itemList = useMemo(() => {
    return userInfo.redDotList.filter(
      (item) =>
        item.itemGroup === itemGroup &&
        item.displayType === ERedDotDisplayType.REDDOT &&
        isUnReadRedDot(item)
    );
  }, [itemGroup, userInfo.redDotList]);

  const getItem = useCallback(
    (item: string) => {
      return itemList?.find((data) => data.item === item);
    },
    [itemList]
  );

  const updateItem = useCallback(
    (itemName: string) => {
      const readDateTime = `${Number(getSafeDateFormat(Date.now(), EDateFormat.REDDOT))}`;
      TMapSender.updateRedDotList([
        {
          itemGroup: itemGroup,
          item: itemName,
          displayType: ERedDotDisplayType.REDDOT,
          readDateTime,
        },
      ]);
      setTimeout(() => {
        // store에 저장하는건 useUserInfo에서 함
        TMapReceiver.setCallback(ECallbackKeys.GET_RED_DOT_LIST, (list: TRedDotItem[]) => {
          dispatch(actions.userInfo.setRedDotList(list));
        });
        TMapSender.getRedDotList();
      }, 500);
    },
    [dispatch, itemGroup]
  );

  return {
    itemList,
    updateItem,
    getItem,
  };
};

export default useRedDot;
