import {TApiResponse, TApiStatus} from 'types/Api';

export type TFestivalItem = {
  poiId: number;
  name: string;
  pkey?: number | string;
  navSeq?: number | string;
  navX?: number;
  navY?: number;
  centerX?: number;
  centerY?: number;
  imageUrl: Nullable<string>;
  date: Nullable<string>;
  areaDepth1Name: Nullable<string>;
  areaDepth2Name: Nullable<string>;
  isRunning?: boolean;
  goingCount: number;
};

// API에서 내려온 데이터셋
export type TFestivalApiResponse = {
  festivalInfos: TFestivalItem[];
  currentPage: number;
  totalPage: number;
  totalCount: number;
};

// 실제 스토어에서 운용할 데이터셋
// (다른 리스트와 규격을 맞추기 위해 'festivalInfos'를 'list'로 변환하여 사용)
export type TFestivalData = Omit<TFestivalApiResponse, 'festivalInfos'> & {list: TFestivalItem[]};

export enum ESortOption {
  POPULARITY = 'popularity', // 인기순
  DISTANCE = 'distance', // 거리순
}

export enum EEventHolding {
  NOW = 'now', // 진행중
  BEFORE = 'before', // 진행예정
}

export type TFestivalParams = {
  regionDepth1Code?: string;
  page?: number;
  size?: number;
  sort?: ESortOption; // 정렬조건 (기본값: popularity, distance 요청 시 userX, userY 필수)
  lon?: number; // wgs84 X좌표
  lat?: number; // wgs84 Y좌표
  eventHolding?: EEventHolding;
};

export type TFestivalPayload = TApiResponse<TFestivalApiResponse>;

export type TFestivalState = TApiStatus<TFestivalData> & {
  sort: ESortOption;
  eventHolding?: EEventHolding;
  lastParam?: TFestivalParams;
};
