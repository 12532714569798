import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import ContentWrap from '../shared/ContentWrap';
import {fetchDiscoveryUserRegion} from 'ducks/tplacehome/slice';
import {useOnce} from 'hooks/useOnce';
import DiscoverySimilar from './DiscoverySimilar';
import DiscoveryWeekend from './DiscoveryWeekend';
import DiscoveryDelicious from './DiscoveryDelicious';
import DiscoveryTrip from './DiscoveryTrip';
import DiscoveryRegionList from './DiscoveryRegionList';
import React, {useCallback, useMemo, useState} from 'react';

import {
  DISCOVERY_BANNER_PROVIDER_CONFIG,
  DISCOVERY_BANNER_SECOND_PROVIDER_CONFIG,
  EAdCode,
} from 'constant/Ads';
// import {isEmpty} from 'utils/lodash';
import TPlaceCuration from '../TPlaceCuration';
import TPlaceAdBanner from '../shared/TPlaceAdBanner';

import {ETPlaceTab} from 'ducks/tplacehome/types';
import usePlaceHome from 'hooks/usePlaceHome';
import InView from 'react-intersection-observer';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import {remoteDefaultData} from 'ducks/remote/defaultData';

// 앱스킴 호출 겹치는 이슈로 인한 딜레이
export const WEEKEND_COMPONENT_RENDER_TIMING = 500;
export const TRIP_COMPONENT_RENDER_TIMING = 1000;

const Discovery = () => {
  const {tplacehome, remote} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const placehomeHook = usePlaceHome();
  const [initLoad, setInitLoad] = useState(false);

  /**
   * initial load
   * 현재 사용자 위치에 대한 발견전용 geo 정보
   */
  useOnce(
    tplacehome.initialDataLoaded && tplacehome.currentTab === ETPlaceTab.DISCOVERY,

    async () => {
      await dispatch(fetchDiscoveryUserRegion({}));
      setInitLoad(true);
    }
  );

  // 여행 재 로드시 스크롤 위치 조정
  const [contentScrollTop, setContentScrollTop] = useState(-1);
  const [contentScrollTopKey, setContentScrollTopKey] = useState(-1);

  const scrollToEl = useCallback((selector: string) => {
    const targetEl = document.querySelector(selector) as HTMLDivElement;
    if (targetEl) {
      setContentScrollTop(targetEl.offsetTop + 1);
      setContentScrollTopKey(Date.now());
    }
  }, []);

  /**
   * 렌더 조건 계산 및 배열에 담기
   */
  const discoveryContentList = useMemo(
    () =>
      remote.tplaceSettings?.discoveryContentList ||
      remoteDefaultData.tplaceSettings.discoveryContentList,
    [remote.tplaceSettings?.discoveryContentList]
  );
  const curationContentList = useMemo(
    () =>
      remote.tplaceSettings?.curationContentList ||
      remoteDefaultData.tplaceSettings.curationContentList,
    [remote.tplaceSettings?.curationContentList]
  );
  const adBannerList = useMemo(
    () =>
      remote.tplaceSettings?.discoveryAdBannerList ||
      remoteDefaultData.tplaceSettings.discoveryAdBannerList,
    [remote.tplaceSettings?.discoveryAdBannerList]
  );
  const contentComponentList = useMemo(() => {
    const result: JSX.Element[] = [];

    discoveryContentList.forEach((item, index) => {
      const logParam = {order_no: result.length};
      switch (item.type) {
        case 'SIMILAR':
          result.push(
            <DiscoverySimilar
              scrollToEl={scrollToEl}
              rootInitLoaded={initLoad}
              logParam={logParam}
            />
          );
          break;
        case 'WEEKEND':
          result.push(
            <DiscoveryWeekend
              scrollToEl={scrollToEl}
              rootInitLoaded={initLoad}
              logParam={logParam}
            />
          );
          break;
        case 'DELICIOUS':
          result.push(
            <DiscoveryDelicious
              scrollToEl={scrollToEl}
              rootInitLoaded={initLoad}
              logParam={logParam}
            />
          );
          break;
        case 'TRIP':
          result.push(
            <DiscoveryTrip scrollToEl={scrollToEl} rootInitLoaded={initLoad} logParam={logParam} />
          );
          break;
        case 'RANKING':
          result.push(
            <InView
              onChange={(isView) =>
                isView && placehomeHook.sendEventDiscovery('view.tmapranking', logParam)
              }
            >
              <DiscoveryRegionList key="discovery_region" />
            </InView>
          );
          break;
        case 'CURATION':
          const targetCurationItem = curationContentList.find(
            (curationItem) => curationItem.id === item.curationId
          );
          if (targetCurationItem) {
            result.push(
              <>
                <InView
                  onChange={(isView) =>
                    isView &&
                    placehomeHook.sendEventDiscovery('view.curating_contents', {
                      curating_content_type: targetCurationItem.type,
                      curating_content_title: targetCurationItem.title,
                      ...logParam,
                    })
                  }
                >
                  <TPlaceCuration item={targetCurationItem} contentIndex={index} />
                </InView>
              </>
            );
          }
          break;
      }
    });
    return result;
  }, [discoveryContentList, scrollToEl, initLoad, curationContentList, placehomeHook]);
  const getAdBannerComp = useCallback(
    (index: number) => {
      let targetAdBanner = adBannerList.find((item) => item.order === index);
      if (!targetAdBanner && index === contentComponentList.length - 1) {
        targetAdBanner = adBannerList.find(
          (item) => item.exposeRequired && (item.order || 0) > index
        );
      }
      if (targetAdBanner) {
        switch (targetAdBanner.type) {
          case 'AD_BANNER_01':
            return (
              <div className={s.ad_wrap}>
                <TPlaceAdBanner
                  adOption={DISCOVERY_BANNER_PROVIDER_CONFIG}
                  adCode={EAdCode.PLACE_DISCOVERY_FIRST}
                  isLogActive={tplacehome.currentTab === ETPlaceTab.DISCOVERY}
                  logData={{custom: {order_no: index}}}
                />
              </div>
            );
          case 'AD_BANNER_02':
            return (
              <div className={s.ad_wrap}>
                <TPlaceAdBanner
                  adOption={DISCOVERY_BANNER_SECOND_PROVIDER_CONFIG}
                  adCode={EAdCode.PLACE_DISCOVERY_SECOND}
                  isLogActive={tplacehome.currentTab === ETPlaceTab.DISCOVERY}
                  logData={{custom: {order_no: index}}}
                />
              </div>
            );
        }
      }
    },
    [adBannerList, contentComponentList.length, tplacehome.currentTab]
  );

  /**
   * render
   */
  return (
    <ContentWrap
      hideBtnTop={false}
      contentScrollTop={contentScrollTop}
      contentScrollTopKey={contentScrollTopKey}
    >
      <div className={s.wrap}>
        {contentComponentList.map((ContentComp, index) => {
          return (
            <React.Fragment key={index}>
              {ContentComp}
              <div className={s.spacer} />
              {getAdBannerComp(index)}
            </React.Fragment>
          );
        })}
      </div>
    </ContentWrap>
  );
};

export default Discovery;
