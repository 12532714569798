import s from 'styles/components/tplacehome/TPlaceDiscoveryPoiItemColumn.module.scss';
import ss from 'styles/components/tplacehome/TPlaceSkeleton.module.scss';
import classNames from 'classnames';

type TProps = {
  thumbHeight: number;
  isTiny?: boolean;
};

const DiscoveryPoiItemColumnSkeleton = (props: TProps) => {
  return (
    <div className={classNames(s.wrap, props.isTiny && s.wrap_tiny)}>
      <button className={s.content}>
        <div className={classNames(s.thumb, ss.skeleton_item)} style={{height: props.thumbHeight}}>
          {' '}
        </div>
        <div className={s.info_wrap}>
          <em
            className={classNames(s.name, ss.skeleton_item)}
            style={{height: props.isTiny ? 20 : 24, width: '50%'}}
          >
            {' '}
          </em>
          <div
            className={classNames(s.star_review_container, ss.skeleton_item)}
            style={{height: props.isTiny ? 16 : 20, width: '90%'}}
          >
            {' '}
          </div>

          <span
            className={classNames(s.category_container, ss.skeleton_item)}
            style={{height: 18, width: '70%'}}
          >
            <span> </span>
          </span>
        </div>
      </button>
    </div>
  );
};

export default DiscoveryPoiItemColumnSkeleton;
