import {TQueryItem, TRedDotItem} from '@lcc/tmap-inapp';
import {TApiResponse, TApiStatus} from 'types/Api';
import {TSKBesselCoordinate, TWgs84Coordinate} from 'types/App';
import {ERecentItemType, TFavoriteRouteItem} from 'types/Search';
import {
  TPoiMyFavoriteDto,
  TRouteMyFavoriteDto,
  TPoiRecentDto,
  TPoiFavoriteDto,
  TPublicTransportRecentDto,
  TPublicTransportFavoriteDto,
  TGeoCoordinate,
} from 'types/PublicTrans';
import {TVsmPublicTransportType} from 'ducks/userInteraction/types';

export enum ECarModel {
  COMPACT = 'CT_COMPACT',
  NORMAL = 'CT_NORMAL',
  MIDDLE = 'CT_MIDDLE',
  LARGE = 'CT_LARGE',
  TRUCK = 'CT_TRUCK',
  SPECIAL = 'CT_SPECIAL',
}

export enum ECarFuel {
  GASOLINE = 'FT_GAS',
  GASOLINE_PREMIUM = 'FT_GASPM',
  DIESEL = 'FT_DIESEL',
  LPG = 'FT_LPG',
  EV = 'FT_EV',
}

export enum EAddressType {
  ROAD = 'ROAD',
  JIBUN = 'JIBUN',
}

export enum EDeleteUserInfoResult {
  SUCCESS = 'success',
}

export type TPersonalPlaceItem = {
  pkey: Nullable<string>;
  poiId: Nullable<string>;
  navSeq: Nullable<string>;
  customName: string;
  rpFlag: number;
  addInfo?: Nullable<string>; // favorite 에서만 내려 옴
  regDateTime?: Nullable<string>; // favorite 에서만 내려 옴
  fullRoadAddr?: Nullable<string>; // favorite 에서만 내려 옴
  fullJibunAddr?: Nullable<string>; // favorite 에서만 내려 옴
  recentDateTime?: Nullable<string>; // recentDestination 에서만 내려 옴
  favId?: Nullable<string>; // 저장에서 내려 옴
} & TSKBesselCoordinate &
  TWgs84Coordinate & {
    personalPoiKey?: string; // front에서 설정하는 uuid
  };

export type TPersonalPublicRecentItem = {
  type: ERecentItemType;
  transportId: string;
  stationId?: number;
  name: string;
  date: string;
  geoCoordinate?: TGeoCoordinate;
  publicTransportName?: string;
  publicTransportType?: TVsmPublicTransportType;
} & TSKBesselCoordinate &
  TWgs84Coordinate;

export type TPersonalPlace = {
  publicRecentDestination: TPersonalPublicRecentItem[];
  recentDestination: TPersonalPlaceItem[];
  favorites: TPersonalPlaceItem[];
  home?: TPersonalPlaceItem;
  office?: TPersonalPlaceItem;
  favoritesPublic: TPersonalPublicRecentItem[];
};

export type TCarInfo = {
  model: Nullable<ECarModel>;
  fuel: Nullable<ECarFuel>;
};

export type TPersonalInfoPayload = TApiResponse<{
  carInfo: TCarInfo;
  favorites: {
    docs: TPersonalPlaceItem[];
    home: TPersonalPlaceItem;
    office: TPersonalPlaceItem;
  };
  recentDestination: {
    docs: TPersonalPlaceItem[];
  };
}>;

export type TFavoriteRoutePayload = TApiResponse<{
  docs: TFavoriteRouteItem[];
}>;

export type TPersonalInfoProps = {
  accessKey: string;
  userKey?: string;
};

export type TUserDataResponse = {
  poiMyFavoriteDto: TPoiMyFavoriteDto;
  routeFavoriteDtos: TRouteMyFavoriteDto[];
  poiRecentDtos: TPoiRecentDto[];
  poiFavoriteDtos: TPoiFavoriteDto[];
  publicTransportRecentDtos: TPublicTransportRecentDto[];
  publicTransportFavoriteDtos: TPublicTransportFavoriteDto[];
};

export type TMapContext = {
  x: number;
  y: number;
  tilt?: number;
  rotate?: number;
  zoom?: number;
};

export type TSaveGroupItem = {
  groupId: string;
  groupType: string; // FAVORITE: 자주가는곳, DEFAULT: 기본그룹, FOOD: 맛집, TRAVEL: 여행, USER: 사용자 생성
  name: string;
  color: string;
  mapDispYn: 'Y' | 'N';
  poiInGroupYn: 'Y' | 'N';
  poiCount: number;
  poiRegDatetime: string; //'20241203195753'
  insDatetime: string;
  updDatetime: string;
};

export type TYNUserSetting = 'Y' | 'N';

export type TUserInfoState = {
  carInfo: TCarInfo;
  personalPlace: TApiStatus<TPersonalPlace>;
  favoriteRoutes: TApiStatus<TFavoriteRouteItem[]>;
  recentQueries: {
    items: TQueryItem[];
    date: number;
  };
  addressType: EAddressType;
  accessKey: string;
  userKey: string;
  euk: string;
  sessionKey: string;
  searchSessionKey: string;
  sessionId: string;
  adId: string;
  deviceServiceVendorId: string;
  name: string;
  phoneNumber: string;

  showFavorite: boolean;
  showRecentlyDestination: boolean;
  device: {
    carrierName: string;
    deviceId: string;
  };
  mapContext?: TMapContext;
  sendDestinationToCar?: TYNUserSetting;
  saveGroupList: TSaveGroupItem[];
  redDotList: TRedDotItem[];
};
