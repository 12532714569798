import {Redirect} from 'react-router-dom';

import {TRouter} from 'types/Router';
import {
  DevRoutes,
  PageRoutes,
  Paths,
  PlaceRoutes,
  ProtoRoutes,
  SearchRoutes,
} from 'constant/RoutePath';
import {isProdEnv} from 'constant/Env';

import CommonLayout from 'components/CommonLayout';
import LegacySearchLayout from 'components/legacySearch/LegacySearchLayout';

// import PlaceMainPage from 'pages/PlaceMainPage';
import PlaceHomePage from 'pages/PlaceHomePage';
import PlaceCategoryPage from 'pages/PlaceCategoryPage';
import FestivalPage from 'pages/FestivalPage';

import ProtoPage from 'pages/ProtoPage';
import ProtoListDrawerPage from 'pages/ProtoListDrawerPage';
import ProtoImagePage from 'pages/ProtoImagePage';
import ProtoAreaWordsPage from 'pages/ProtoAreaWordsPage';
import ProtoCustomSelectPage from 'pages/ProtoCustomSelectPage';
import ProtoPubImagePage from 'pages/ProtoPubImagePage';
import ProtoBannerPage from 'pages/ProtoBannerPage';
import ProtoKeywordPage from 'pages/ProtoKeywordPage';

import DevPage from 'pages/DevPage';
import DevRedDotPage from 'pages/DevRedDotPage';
import DevDeepLinkPage from 'pages/DevDeepLinkPage';

import DevHybridBridgePage from 'pages/DevHybridBridgePage';

import styles from 'styles/pages/Routes.module.scss';
import {isNewApp} from 'utils/tmapUtils';
import SearchLayout from 'components/search/SearchLayout';
import DevAppInterfacePage from 'pages/DevAppInterfacePage';
import {EPlaceCategoryType} from 'types/App';
import RankingPage from 'pages/RankingPage';
import ProtoCurationPage from 'pages/ProtoCurationPage';

const routes: TRouter[] = [
  {
    path: SearchRoutes,
    component: (props) =>
      isNewApp() ? (
        <SearchLayout {...{...props, className: styles.search_wrap}} />
      ) : (
        <LegacySearchLayout {...{...props, className: styles.search_wrap}} />
      ),
    routes: [
      {
        component: () => <Redirect to={Paths.SearchMain} />,
      },
    ],
  },
  {
    path: PlaceRoutes,
    component: (props) => <CommonLayout {...{...props, className: styles.place_wrap}} />,
    routes: [
      {
        path: Paths.PlaceMain,
        // component: PlaceMainPage,
        component: PlaceHomePage,
      },
      {
        path: Paths.PlaceCategory,
        component: ({location}) => {
          const searchParams = new URLSearchParams(location.search);
          const type = searchParams.get('type');

          return type === EPlaceCategoryType.RANKING ? <RankingPage /> : <PlaceCategoryPage />;
        },
      },
      {
        component: () => <Redirect to={Paths.PlaceMain} />,
      },
    ],
  },

  {
    path: PageRoutes,
    component: (props) => <CommonLayout {...{...props, className: styles.place_wrap}} />,
    routes: [
      {
        path: Paths.PageFestival,
        component: FestivalPage,
      },
      {
        // 별도 fallback 처리 없음
        component: () => null,
      },
    ],
  },

  {
    path: ProtoRoutes,
    component: (props) =>
      isProdEnv ? (
        <Redirect to={Paths.PlaceMain} />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Proto,
        component: ProtoPage,
      },
      {
        title: '검색 > 배너',
        path: Paths.ProtoBanner,
        component: ProtoBannerPage,
      },
      {
        title: '검색 > 키워드 광고',
        path: Paths.ProtoKeyword,
        component: ProtoKeywordPage,
      },
      {
        title: '티지금 > 메인페이지',
        path: Paths.ProtoListDrawer,
        component: ProtoListDrawerPage,
      },
      {
        title: '티지금 > 이미지',
        path: Paths.ProtoImage,
        component: ProtoImagePage,
      },
      {
        title: '티지금 > 지역검색어',
        path: Paths.ProtoAreaWords,
        component: ProtoAreaWordsPage,
      },
      {
        title: '티지금 > 커스텀셀렉트박스',
        path: Paths.ProtoCustomSelect,
        component: ProtoCustomSelectPage,
      },
      {
        title: '티지금 > Static 이미지',
        path: Paths.ProtoPubImage,
        component: ProtoPubImagePage,
      },

      {
        title: '티플레이스 홈 > 큐레이션',
        path: Paths.ProtoCuration,
        component: ProtoCurationPage,
      },

      {
        component: () => <Redirect to={Paths.Proto} />,
      },
    ],
  },

  {
    path: DevRoutes,
    component: (props) =>
      isProdEnv ? (
        <Redirect to={Paths.PlaceMain} />
      ) : (
        <CommonLayout {...{...props, className: styles.place_wrap}} />
      ),
    routes: [
      {
        path: Paths.Dev,
        component: DevPage,
      },
      {
        title: '검색&티지금 > 딥링크',
        path: Paths.DevDeepLink,
        component: DevDeepLinkPage,
      },
      {
        title: '티지금 > 레드닷',
        path: Paths.DevRedDot,
        component: DevRedDotPage,
      },
      {
        title: '검색 > 하이브리드',
        path: Paths.DevHybridBridge,
        component: DevHybridBridgePage,
      },
      {
        title: '검색 > 앱인터페이스',
        path: Paths.DevAppInterface,
        component: DevAppInterfacePage,
      },
      {
        component: () => <Redirect to={Paths.Dev} />,
      },
    ],
  },

  {
    component: () => <Redirect to={Paths.SearchMain} />,
  },
];

export default routes;
