import {EImageSize} from 'types/Image';
import DiscoveryPoiItemColumn from './DiscoveryPoiItemColumn';
import MoreButton from './MoreButton';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import useFavorite from 'hooks/useFavorite';
import usePlaceHome from 'hooks/usePlaceHome';
import {useState, useMemo, useCallback} from 'react';
import {useAppSelector} from 'ducks/hooks';
import {isEmpty} from 'utils/lodash';
import InView from 'react-intersection-observer';

type TProps = {
  title: string;
  itemList: any[];
  moduleName: string;
};

const DiscoveryDeliciousContent = (props: TProps) => {
  const {userInfo, map} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const itemCount = 3;
  const [currentPage, setCurrentPage] = useState(0);

  const currentList = useMemo(() => {
    const currentCount = currentPage * itemCount;
    return props.itemList && props.itemList.slice(currentCount, currentCount + itemCount);
  }, [currentPage, props.itemList]);

  const totalPages = useMemo(() => {
    return Math.ceil((props.itemList?.length || 0) / itemCount);
  }, [props.itemList?.length]);

  const {checkIsFavorite} = useFavorite({list: currentList || []});

  const onClickMoreButton = useCallback(() => {
    let nextPage = currentPage + 1;
    if (nextPage === totalPages) {
      nextPage = 0;
    }
    setCurrentPage(nextPage);
  }, [currentPage, totalPages]);

  const isHideState = useMemo(() => {
    return isEmpty(props.itemList) || props.itemList.length < itemCount;
  }, [props.itemList]);

  if (isHideState) {
    return null;
  }

  return (
    <div className={s.category_wrap}>
      <h2 className={s.title}>{props.title}</h2>
      <ul className={s.list_wrap}>
        {currentList.map((item, index) => {
          const itemLogParams = {
            module_cate: props.title,
            pkey: item.pkey,
            poi_id: item.poiId,
            poi_name: item.poiName,
            poi_cate: item.svcCategoryName,
            poi_region_1D: item.regionName1,
            poi_region_2D: item.regionName2,
            guide_count: item.visitCount3Month || null,
            review_count: item.reviewDto.reviewCount || null,
            avg_rating: item.reviewDto.reviewGrade || null,
          };
          return (
            <InView
              as="li"
              key={`${item.poiId}_${index}`}
              className={s.cell}
              onChange={(inView) =>
                inView && placehomeHook.sendEventDiscovery('view.same_taste_poi', itemLogParams)
              }
            >
              <DiscoveryPoiItemColumn
                data={item}
                thumbHeight={110}
                isFavorite={checkIsFavorite(item)}
                onClickDetail={() =>
                  placehomeHook.sendEventDiscovery('tap.same_taste_poi', {
                    euk: userInfo.euk,
                    region_1D: map.userPositionReverseGeoInfo?.regionName1,
                    region_2D: map.userPositionReverseGeoInfo?.regionName2,
                    region_3D: map.userPositionReverseGeoInfo?.regionName3,
                    ...itemLogParams,
                  })
                }
                thumbnailSize={EImageSize.THUMBNAIL}
                isTiny={true}
                moduleName={props.moduleName}
                itemIndex={currentPage * itemCount + index}
              />
            </InView>
          );
        })}
      </ul>
      {totalPages > 1 && (
        <MoreButton
          current={currentPage + 1}
          total={totalPages}
          onClick={onClickMoreButton}
          moduleName={props.moduleName}
        />
      )}
    </div>
  );
};

export default DiscoveryDeliciousContent;
