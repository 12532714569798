import classNames from 'classnames';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';
import {useCallback, useEffect, useMemo, useState} from 'react';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';

import {ReactComponent as IconPlaceRestaurant} from 'resource/images/@tmds_solid/ico_place_restaurant_solid.svg';
import {ReactComponent as IconPlaceCafe} from 'resource/images/@tmds_solid/ico_place_cafe_solid.svg';
import TmdsIcon from 'components/@tmds/TmdsIcon';
import {IcInfoFill} from 'components/@tmds/icons/v1.2/IcInfoFill';
import InView from 'react-intersection-observer';
import {FRONTMAN_API_PATH} from 'constant/Api';
import {frontmanFetcher} from 'utils/fetcher';
import DiscoveryDeliciousContent from './DiscoveryDeliciousContent';
import {IcoExit} from 'components/@tmds/icons/IcoExit';
import compact from 'lodash/compact';
import DiscoveryDeliciousSkeleton from './DiscoveryDeliciousSkeleton';
import {ELoadState, TDiscoveryContentProps} from '../types';
import {setTimeEvent} from 'utils/mixPanel';

const DiscoveryDelicious = (props: TDiscoveryContentProps) => {
  const {tplacehome, map} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const [loadState, setLoadState] = useState(ELoadState.READY);
  const [poiListRestaurant, setPoiListRestaurant] = useState([]);
  const [poiListCafe, setPoiListCafe] = useState([]);
  const [tooltipView, setTooltipView] = useState(false);

  const fetchData = useCallback(async () => {
    setLoadState(ELoadState.LOADING);
    try {
      const params = {
        regionName1: tplacehome.discoveryData.userRegionInfo?.region1Name || '',
        rows: 15, // 20개 기본?
        userLat: map.userPosition?.lat,
        userLon: map.userPosition?.lon,
      };
      const res = await frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_DELICIOUS_LIST, {
        params,
      });

      const list = compact(res.data.data);
      setPoiListRestaurant(list.filter((item) => item.category === '음식점'));
      setPoiListCafe(list.filter((item) => item.category !== '음식점'));
      setLoadState(ELoadState.LOADED);
    } catch (e) {
      setLoadState(ELoadState.ERROR);
    }
  }, [
    map.userPosition?.lat,
    map.userPosition?.lon,
    tplacehome.discoveryData.userRegionInfo?.region1Name,
  ]);

  useEffect(() => {
    if (props.rootInitLoaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rootInitLoaded]);

  const toggleTooltip = useCallback(() => {
    setTooltipView(!tooltipView);
  }, [tooltipView]);

  useEffect(() => {
    const onClickWindow = (e) => {
      const targetEl = e.target as HTMLElement;
      const tooltipEl = targetEl.closest('[data-tooltip]');
      if (!tooltipEl) {
        setTooltipView(false);
      }
    };
    window.addEventListener('click', onClickWindow);
    return () => {
      window.removeEventListener('click', onClickWindow);
    };
  }, []);

  const [wrapInView, setWrapInView] = useState(false);
  useEffect(() => {
    if (wrapInView && loadState === ELoadState.LOADED) {
      setTimeEvent('tap.same_taste_poi'); // poi click time event 시작점
      placehomeHook.sendEventDiscovery('view.same_taste', {
        list_num: poiListCafe.length + poiListRestaurant.length,
        ...props.logParam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapInView, loadState]);

  const isLoading = useMemo(() => {
    return [ELoadState.LOADING, ELoadState.READY].includes(loadState);
  }, [loadState]);

  const isHideState = useMemo(() => {
    return (
      ELoadState.ERROR === loadState ||
      (ELoadState.LOADED === loadState && poiListCafe.length < 3 && poiListRestaurant.length < 3)
    );
  }, [loadState, poiListCafe, poiListRestaurant]);

  if (isHideState) {
    return null;
  }

  return (
    <InView
      as="section"
      className={classNames(s.section, s.section_delicious)}
      onChange={setWrapInView}
    >
      <header className={s.header} style={{marginBottom: '24px'}}>
        <h1 className={s.title}>
          <span className={s.sub}>실패없는 티맵 찐 맛집</span>
          <em className={s.main}>음식점 · 카페</em>
        </h1>
        <div className={s.icon_wrap}>
          <div className={classNames(s.icon, s.restaurant)}>
            <TmdsIcon color="tmobiBlue400" width={18} height={18} Icon={<IconPlaceRestaurant />} />
          </div>
          <div className={classNames(s.icon, s.cafe)}>
            <TmdsIcon color="tmobiPurple400" width={18} height={18} Icon={<IconPlaceCafe />} />
          </div>
        </div>
      </header>
      <div className={s.content_wrap}>
        <button className={s.info_button} onClick={toggleTooltip} data-tooltip>
          <span>길안내 수</span>
          <IcInfoFill width={16} height={16} color="gray300" />
        </button>
        {tooltipView && (
          <div className={s.info_tooltip} data-tooltip>
            <h3>길안내 수</h3>
            <span className={s.desc}>
              <i>·</i>3개월 간 집계된 누적 길안내 수 입니다.
            </span>
            <button className={s.close_button} onClick={toggleTooltip}>
              <IcoExit width={20} height={20} color="gray" />
            </button>
          </div>
        )}
        {isLoading ? (
          <DiscoveryDeliciousSkeleton />
        ) : (
          <>
            <DiscoveryDeliciousContent
              title="음식점"
              itemList={poiListRestaurant}
              moduleName="DELICIOUS_restaurant"
            />
            <DiscoveryDeliciousContent
              title="카페"
              itemList={poiListCafe}
              moduleName="DELICIOUS_cafe"
            />
          </>
        )}
      </div>
    </InView>
  );
};

export default DiscoveryDelicious;
