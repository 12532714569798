import {TPlaceTagItem} from 'types/App';

export const tplacehomeUtils = {
  getTagDisplayInfos: (
    tagInfos: Partial<
      Pick<
        TPlaceTagItem,
        | 'isPopular'
        | 'isTVrestaurant'
        | 'isTmapReservation'
        | 'isTmapWaiting'
        | 'isParking'
        | 'isValetParking'
      >
    >
  ) => {
    const result: {label: string; color?: string; backgroundColor?: string}[] = [];
    if (tagInfos.isPopular) {
      result.push({
        color: 'textSuccessBolder',
        backgroundColor: 'backgroundSuccessSecondary',
        label: '티맵인기',
      });
    }
    if (tagInfos.isTVrestaurant) {
      result.push({
        color: 'tmobiTeal600',
        backgroundColor: 'tmobiTeal100',
        label: 'TV맛집',
      });
    }
    if (tagInfos.isTmapReservation) {
      result.push({label: '예약', color: 'tmobiPurple600', backgroundColor: 'tmobiPurple100'});
    }
    if (tagInfos.isTmapWaiting) {
      result.push({label: '웨이팅', color: 'tmobiPurple600', backgroundColor: 'tmobiPurple100'});
    }
    if (tagInfos.isParking) {
      result.push({label: '주차'});
    }
    if (tagInfos.isValetParking) {
      result.push({label: '발렛'});
    }
    return result;
  },
};
