import classNames from 'classnames';
import {IcoOutlink} from 'components/@tmds/icons/IcoOutlink';
import Thumbnail from 'components/Thumbnail';
import {useAppSelector} from 'ducks/hooks';
import useFavorite from 'hooks/useFavorite';
import usePlaceHome from 'hooks/usePlaceHome';
import {useCallback, useEffect, useMemo, useState} from 'react';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import MoreButton from './MoreButton';
import DiscoveryPoiItemColumn from './DiscoveryPoiItemColumn';
import {frontmanFetcher} from 'utils/fetcher';
import {FRONTMAN_API_PATH} from 'constant/Api';
import {TMapSenderCustom} from 'utils/tmapInAppCustom';
import InView from 'react-intersection-observer';
import compact from 'lodash/compact';
import {isEmpty} from 'utils/lodash';
import DiscoverySimilarSkeleton from './DiscoverySimilarSkeleton';
import {ELoadState, TDiscoveryContentProps} from '../types';
import {setTimeEvent} from 'utils/mixPanel';

const DiscoverySimilar = (props: TDiscoveryContentProps) => {
  const {tplacehome, userInfo, map} = useAppSelector((state) => state);
  const placehomeHook = usePlaceHome();

  const itemCount = 4;
  const [loadState, setLoadState] = useState(ELoadState.READY);
  const [visitedPlace, setVisitedPlace] = useState<any>(null);
  const [poiList, setPoiList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [userName, setUserName] = useState('');

  const recommendedText = useMemo(() => {
    switch (visitedPlace?.reasonType) {
      case 'TRIP':
      case 'PLACE_DETAIL':
        return '최근 방문/탐색한 장소 기반 추천';
      case 'POPULAR':
      default:
        return '티맵에서 많이 가고 있는 장소 기반 추천';
    }
  }, [visitedPlace?.reasonType]);

  const currentList = useMemo(() => {
    const currentCount = currentPage * itemCount;
    return poiList && poiList.slice(currentCount, currentCount + itemCount);
  }, [currentPage, poiList]);

  const totalPages = useMemo(() => {
    return Math.ceil((poiList?.length || 0) / itemCount);
  }, [poiList?.length]);

  const {checkIsFavorite} = useFavorite({list: currentList || []});

  const fetchData = useCallback(async () => {
    setLoadState(ELoadState.LOADING);
    try {
      const params = {
        regionName1: tplacehome.discoveryData.userRegionInfo?.region1Name || '',
        rows: 20,
        userLat: map.userPosition?.lat,
        userLon: map.userPosition?.lon,
      };
      const res = await frontmanFetcher.get(FRONTMAN_API_PATH.GET_DISCOVERY_SIMILAR_LIST_V2, {
        params,
      });
      setVisitedPlace(res.data.data.visitedPlace);
      setPoiList(compact(res.data.data.recommendList));
      setCurrentPage(0);
      setLoadState(ELoadState.LOADED);
    } catch (e) {
      setLoadState(ELoadState.ERROR);
    }
  }, [
    map.userPosition?.lat,
    map.userPosition?.lon,
    tplacehome.discoveryData.userRegionInfo?.region1Name,
  ]);

  const handleClickBasePoi = useCallback(() => {
    placehomeHook.sendEventDiscovery('tap.my_taste_main_poi', {
      poi_id: visitedPlace?.poiId,
      poi_name: visitedPlace.poiName,
      euk: userInfo.euk,
    });
    placehomeHook.openPoiDetail({poiId: visitedPlace?.poiId});
  }, [placehomeHook, userInfo.euk, visitedPlace?.poiId, visitedPlace?.poiName]);

  const onClickMoreButton = useCallback(() => {
    let nextPage = currentPage + 1;
    if (nextPage === totalPages) {
      nextPage = 0;
    }
    setCurrentPage(nextPage);
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (props.rootInitLoaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rootInitLoaded]);

  useEffect(() => {
    TMapSenderCustom.getUserSetting('user.name').then((resArr) => {
      const nickName = resArr?.[0];
      setUserName(nickName ? `${nickName} 님` : '고객님');
    });
  }, []);

  const isLoading = useMemo(() => {
    return [ELoadState.LOADING, ELoadState.READY].includes(loadState);
  }, [loadState]);

  const isHideState = useMemo(() => {
    const loadError = ELoadState.ERROR === loadState;
    // 4개미만인 경우는 없다. (최소 12개 보장) 만약을 대비해 임의로 예외처리함.
    const countError =
      ELoadState.LOADED === loadState && (isEmpty(poiList) || poiList.length < itemCount);
    return loadError || countError;
  }, [poiList, loadState]);

  const [wrapInView, setWrapInView] = useState(false);
  useEffect(() => {
    if (wrapInView && loadState === ELoadState.LOADED) {
      setTimeEvent('tap.my_taste_poi'); // poi click time event 시작점
      placehomeHook.sendEventDiscovery('view.my_taste', {
        list_num: poiList.length,
        type: visitedPlace.reasonType,
        ...props.logParam,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapInView, loadState]);

  if (isHideState) {
    return null;
  }

  return (
    <InView
      as="section"
      className={classNames(s.section, s.section_similar)}
      onChange={setWrapInView}
    >
      <header className={s.header} style={{marginBottom: '20px'}}>
        <h1 className={s.title}>
          <span className={s.sub}>{userName}이 좋아할만한</span>
          <em className={s.main}>맛집 추천</em>
        </h1>
      </header>
      {isLoading ? (
        <DiscoverySimilarSkeleton />
      ) : (
        <>
          <InView
            as="div"
            className={s.base_poi}
            onChange={(inView) =>
              inView &&
              placehomeHook.sendEventDiscovery('view.my_taste_main_poi', {
                poi_id: visitedPlace.poiId,
                poi_name: visitedPlace.poiName,
              })
            }
            onClick={handleClickBasePoi}
          >
            <div className={s.bg_gradient} />
            <Thumbnail imgSrc={visitedPlace.imageUrl} alt="" className={s.bg_img} />
            <div className={s.title_wrap}>
              <p className={s.sub_title}>{recommendedText}</p>
              <h2 className={s.title}>
                <span>
                  {visitedPlace?.poiName}{' '}
                  <IcoOutlink color="white" width={13} height={13} className={s.icon} />
                </span>
              </h2>
            </div>
          </InView>
          <div className={classNames(s.rounded_content_wrap, s.content_wrap)}>
            <ul className={s.list_wrap}>
              {currentList.map((item, index) => {
                const itemLogParams = {
                  main_poi_id: visitedPlace.poiId,
                  list_seq: index,
                  pkey: item.pkey,
                  poi_id: item.poiId,
                  poi_name: item.poiName,
                  poi_cate: item.svcCategoryName,
                  poi_region_1D: item.regionName1,
                  poi_region_2D: item.regionName2,
                  guide_count: item.visitCount3Month || null,
                  review_count: item.reviewDto.reviewCount || null,
                  avg_rating: item.reviewDto.reviewGrade || null,
                };
                return (
                  <InView
                    as="li"
                    key={`${item.poiId}_${index}`}
                    className={s.cell}
                    onChange={(inView) =>
                      inView && placehomeHook.sendEventDiscovery('view.my_taste_poi', itemLogParams)
                    }
                  >
                    <DiscoveryPoiItemColumn
                      data={item}
                      thumbHeight={110}
                      isFavorite={checkIsFavorite(item)}
                      onClickDetail={() =>
                        placehomeHook.sendEventDiscovery('tap.my_taste_poi', {
                          euk: userInfo.euk,
                          region_1D: map.userPositionReverseGeoInfo?.regionName1,
                          region_2D: map.userPositionReverseGeoInfo?.regionName2,
                          region_3D: map.userPositionReverseGeoInfo?.regionName3,
                          ...itemLogParams,
                        })
                      }
                      moduleName="SIMILAR"
                      itemIndex={currentPage * itemCount + index}
                    />
                  </InView>
                );
              })}
            </ul>
            {totalPages > 1 && (
              <MoreButton
                current={currentPage + 1}
                total={totalPages}
                onClick={onClickMoreButton}
                moduleName="SIMILAR"
              />
            )}
          </div>
        </>
      )}
    </InView>
  );
};

export default DiscoverySimilar;
