import layoutSlice from 'ducks/layout/slice';
import placeSlice from 'ducks/place/slice';
import mapSlice from 'ducks/map/slice';
import userInfoSlice from 'ducks/userInfo/slice';
import userInteractionSlice from 'ducks/userInteraction/slice';
import logSlice from 'ducks/log/slice';
import searchSlice from 'ducks/search/slice';
import categorySlice from 'ducks/category/slice';
import remoteSlice from 'ducks/remote/slice';
import searchRecommendSlice from './searchRecommend/slice';
import tRankSlice from './rank/slice';
import tplacehomeSlice from './tplacehome/slice';
import festivalSlice from './festival/slice';

const exportModule = {
  layout: layoutSlice.actions,
  tplacehome: tplacehomeSlice.actions,
  place: placeSlice.actions,
  map: mapSlice.actions,
  userInfo: userInfoSlice.actions,
  userInteraction: userInteractionSlice.actions,
  log: logSlice.actions,
  search: searchSlice.actions,
  category: categorySlice.actions,
  remote: remoteSlice.actions,
  searchRecommend: searchRecommendSlice.actions,
  tRank: tRankSlice.actions,
  festival: festivalSlice.actions,
};

export default exportModule;
