import {useCallback, useEffect, useState} from 'react';
import {TDiscoveryItem} from 'ducks/tplacehome/types';
import usePlaceHome from 'hooks/usePlaceHome';
import Thumbnail from 'components/Thumbnail';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';

import s from 'styles/components/tplacehome/TPlaceDiscoveryPoiItemColumn.module.scss';
import {ReactComponent as IcStarSvg} from 'resource/images/ic_star_stroke.svg';
import {ReactComponent as IcStarFillSvg} from 'resource/images/ic_star_stroke_fill.svg';
import classNames from 'classnames';
import {getDistance, setComma} from 'utils/formatter';
import {IcStarFill} from 'components/@tmds/icons/v1.2/IcStarFill';
import DiscoveryDividerIcon from './comps/DividerIcon';
// import {IcoDotBetweenBold} from 'components/@tmds/icons/IcoDotBetweenBold';
// import {IcDotBetween} from 'components/@tmds/icons/v1.2/IcDotBetween';
// import {IcDotBetweenBold} from 'components/@tmds/icons/v1.2/IcDotBetweenBold';

type TProps = {
  data: TDiscoveryItem;
  thumbHeight: number;
  isFavorite?: boolean;
  onClickDetail?: () => void;
  thumbnailSize?: EImageSize;
  moduleName?: string;
  isTiny?: boolean;
  itemIndex: number;
};

const DiscoveryPoiItemColumn = ({
  data,
  thumbHeight,
  thumbnailSize,
  onClickDetail,
  moduleName,
  ...props
}: TProps) => {
  const {poiName, imageUrl, reviewDto, svcCategoryName, distance, regionName1, regionName2} = data;
  const {reviewCount, reviewGrade} = reviewDto;
  const [isFavorite, setIsFavorite] = useState(false);

  const placehomeHook = usePlaceHome();
  const handleToggleFavorite = useCallback(() => {
    placehomeHook.toggleFavorite(data, {itemIndex: props.itemIndex, moduleName}).then((isOn) => {
      setIsFavorite(isOn ? true : false);
    });
  }, [data, moduleName, placehomeHook, props.itemIndex]);

  const handleMoveToDetail = useCallback(() => {
    onClickDetail?.();
    placehomeHook.openPoiDetail(data);
  }, [data, onClickDetail, placehomeHook]);

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  const renderReview = () => {
    // 리뷰수가 10개 이상일때만 별점 노출
    // https://tmobi.atlassian.net/browse/TPLACEHOME-519
    if (reviewGrade && reviewCount && reviewCount >= 10) {
      return (
        <>
          <em className={s.star_point}>
            <IcStarFill width={16} height={16} color={'tmobiTeal400'} />
            {reviewGrade.toFixed(1)}
          </em>
          <span>({setComma(reviewCount)})</span>
        </>
      );
    } else if (reviewCount) {
      return (
        <span>
          리뷰 <em>{setComma(reviewCount)}</em>
        </span>
      );
    } else if (distance) {
      return (
        <span>
          현위치에서 <em>{getDistance(distance)}</em>
        </span>
      );
    } else {
      <span>-</span>;
    }
  };

  return (
    <div
      className={classNames(s.wrap, props.isTiny && s.wrap_tiny)}
      data-discovery-item={data.poiId}
    >
      <button className={s.content} onClick={handleMoveToDetail}>
        <div className={s.thumb} style={{height: thumbHeight}}>
          <Thumbnail
            imgSrc={getImageUrl(imageUrl, thumbnailSize)}
            alt={`${poiName} 이미지`}
            className={s.img}
          />
          {data.visitCount3Month && (
            <>
              {props.isTiny ? (
                <div className={s.route_num_tag}>
                  길안내 <em>{setComma(data.visitCount3Month)}번</em>
                </div>
              ) : (
                <div className={s.route_num_tag}>
                  3개월 길안내 <em>{setComma(data.visitCount3Month)}번</em>
                </div>
              )}
            </>
          )}
        </div>
        <div className={s.info_wrap}>
          <em className={s.name}>{poiName}</em>
          <div className={s.star_review_container}>{renderReview()}</div>

          <span className={s.category_container}>
            <span>
              {regionName1} {regionName2}
            </span>
            {!props.isTiny && (
              <>
                {/* <IcoDotBetweenBold width={12} height={12} color="gray200" /> */}
                {/* <IcDotBetween width={12} height={12} color="gray200" /> */}
                <DiscoveryDividerIcon color="#E0E2E8" />
                <span className={s.ellipsis}>{svcCategoryName}</span>
              </>
            )}
          </span>
        </div>
      </button>
      <button
        type="button"
        className={s.favorite}
        style={{top: `${thumbHeight - 32}px`}}
        onClick={handleToggleFavorite}
      >
        {isFavorite ? <IcStarFillSvg /> : <IcStarSvg />}
      </button>
    </div>
  );
};

export default DiscoveryPoiItemColumn;
