import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';

import {times} from 'utils/lodash';
import DiscoveryPoiItemColumnSkeleton from './DiscoveryPoiItemColumnSkeleton';
import MoreButton from './MoreButton';

const DiscoveryDeliciousSkeleton = () => {
  return (
    <>
      <div className={s.category_wrap}>
        <h2 className={s.title}>음식점</h2>
        <ul className={s.list_wrap}>
          {times(3).map(() => {
            return (
              <li className={s.cell}>
                <DiscoveryPoiItemColumnSkeleton thumbHeight={110} isTiny={true} />
              </li>
            );
          })}
        </ul>
        <MoreButton current={'-'} total={'-'} />
      </div>
      <div className={s.category_wrap}>
        <h2 className={s.title}>카페</h2>
        <ul className={s.list_wrap}>
          {times(3).map(() => {
            return (
              <li className={s.cell}>
                <DiscoveryPoiItemColumnSkeleton thumbHeight={110} isTiny={true} />
              </li>
            );
          })}
        </ul>
        <MoreButton current={'-'} total={'-'} />
      </div>
    </>
  );
};

export default DiscoveryDeliciousSkeleton;
