import {useCallback, useMemo} from 'react';
import TMapSender, {ECallbackKeys, TMapReceiver, TPoiDataJson} from '@lcc/tmap-inapp';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {fetchUserData} from 'ducks/userInfo/slice';
import useMoveToTarget from './useMoveToTarget';
import {TPoiDetailParam} from 'types/App';
import {additionalInfoList, EAdditionalInfo, ETPlaceTab} from 'ducks/tplacehome/types';
import LogManager, {updateMeta} from 'utils/logManager';
import {EPageType, EActionId} from 'constant/Log';
import actions from 'ducks/actions';
import {EListMode} from 'types/ListDrawer';
import {setPageId} from 'utils/mixPanel';
import {useParamLog} from './useParamLog';
import {isOverNewFavoriteVersion} from 'utils/tmapUtils';
import {parsePoiInfoToNavInfo} from 'utils/search';

enum EPageId {
  AROUND_ME = '/main/place/around',
  DISCOVERY = '/main/place/discover',
}

const usePlaceHome = () => {
  const dispatch = useAppDispatch();
  const {moveToDetail} = useMoveToTarget();
  const {userInfo, tplacehome} = useAppSelector((state) => state);
  const {referrer} = useParamLog();

  /**
   * log 관련
   */
  const pageId = useMemo(() => {
    return EPageId[tplacehome.currentTab];
  }, [tplacehome.currentTab]);

  const sendExpose = useCallback((custom?: Record<string, any>) => {
    LogManager.sendPageView(EPageType.PLACE_MAIN, custom);
    // eslint-disable-next-line no-console
    // console.log('=== sendExpose', custom);
  }, []);

  const sendEvent = useCallback((actionId: string, custom?: Record<string, any>) => {
    LogManager.sendClickLog(EPageType.PLACE_MAIN, actionId as EActionId, custom);
    // eslint-disable-next-line no-console
    // console.log('=== sendEvent', actionId, custom);
  }, []);

  // 플리킹시 inView동작되는 현상이 있어서 탭 체크 후 발송. sendEventAroundMe, sendEventDiscovery
  // 플리킹 한계선 넘을때 발생됨, 내주변에서 오른쪽으로 밀때 발견 inView 호출됨. 발견에서 왼쪽으로 밀때 내주변 inView 호출됨.
  // 노출(view...) 이벤트를 위함. 일반 click 및 tab은 sendEvent 사용 가능.
  const sendEventAroundMe = useCallback(
    (actionId: string, custom?: Record<string, any>) => {
      // eslint-disable-next-line no-console
      // console.log('==== sendEventAroundMe', actionId, custom);
      if (tplacehome.currentTab === ETPlaceTab.AROUND_ME) {
        sendEvent(actionId, custom);
      }
    },
    [sendEvent, tplacehome.currentTab]
  );
  const sendEventDiscovery = useCallback(
    (actionId: string, custom?: Record<string, any>) => {
      // eslint-disable-next-line no-console
      // console.log('==== sendEventDiscovery', actionId, custom);
      if (tplacehome.currentTab === ETPlaceTab.DISCOVERY) {
        sendEvent(actionId, custom);
      }
    },
    [sendEvent, tplacehome.currentTab]
  );
  const sendCurrationTapEvent = useCallback(
    (type: string, title: string, itemIndex: number, contentIndex: number) => {
      const params = {
        euk: userInfo.euk,
        curating_contents_type: type,
        curating_contents_title: title,
        list_seq: itemIndex + 1,
        order_no: contentIndex,
      } as any;
      if (tplacehome.currentTab === ETPlaceTab.AROUND_ME) {
        params.list_num = tplacehome.aroundMeData.result.data.filteredList.length;
      }
      sendEvent('tap.curating_contents', params);
    },
    [
      sendEvent,
      tplacehome.aroundMeData.result.data.filteredList.length,
      tplacehome.currentTab,
      userInfo.euk,
    ]
  );

  const updatePageId = useCallback(() => {
    updateMeta({
      page_id: pageId,
    });
    setPageId(pageId);
  }, [pageId]);

  const getDrawerModeIndex = useCallback((mode?: EListMode) => {
    const drawerIndexList = [EListMode.BOTTOM, EListMode.CENTER, EListMode.TOP]; // 0,1,2
    return drawerIndexList.findIndex((v) => v === mode);
  }, []);

  const logInit = useCallback(() => {
    LogManager.init({
      sessionId: userInfo.sessionId,
      accessKey: userInfo.accessKey,
      sessionKey: userInfo.sessionKey,
      userKey: userInfo.userKey,
      deviceId: userInfo.device.deviceId,
      carrierName: userInfo.device.carrierName,
      pageId: pageId,
      pageType: EPageType.PLACE_MAIN,
      title: '티맵랭킹',
      referrer,
    });
    dispatch(actions.log.setInitialize(true));
    // eslint-disable-next-line no-console
    // console.log('=== initialize');
  }, [
    dispatch,
    pageId,
    referrer,
    userInfo.accessKey,
    userInfo.device.carrierName,
    userInfo.device.deviceId,
    userInfo.sessionId,
    userInfo.sessionKey,
    userInfo.userKey,
  ]);

  const openPoiDetail = useCallback(
    (poiItem: Pick<TPoiDetailParam, 'poiId' | 'pkey' | 'navX' | 'navY'>) => {
      moveToDetail(parsePoiInfoToNavInfo(poiItem as TPoiDataJson));
    },
    [moveToDetail]
  );

  const toggleFavorite = useCallback(
    async (
      poiDataJson: {
        centerX?: string | number;
        centerY?: string | number;
        navSeq: string | number;
        navX: string | number;
        navY: string | number;
        pkey: string;
        poiId: string | number;
        poiName: string;
        mainCategory?: string | number; // 내주변에서만 있음
      },
      logParams: {
        itemIndex: string | number;
        moduleName?: string;
      }
    ) => {
      return new Promise((resolve) => {
        // 단말에 전송시 string타입으로 변경해야 함
        const params: TPoiDataJson = {
          ...poiDataJson,
          centerX: String(poiDataJson.centerX || ''),
          centerY: String(poiDataJson.centerY || ''),
          navSeq: String(poiDataJson.navSeq || ''),
          navX: String(poiDataJson.navX || ''),
          navY: String(poiDataJson.navY || ''),
          pkey: String(poiDataJson.pkey || ''),
          poiId: String(poiDataJson.poiId),
          poiName: poiDataJson.poiName,
          address: '',
          tel: '',
          rpFlag: 0,
        };

        const defaultLogParam = {
          poi_id: poiDataJson.poiId,
          pkey: poiDataJson.pkey,
          categorycode: poiDataJson.mainCategory || '',
          type: 'list',
          list_num: logParams.itemIndex,
          module: logParams.moduleName || '',
        };

        if (isOverNewFavoriteVersion()) {
          TMapReceiver.setCallback(ECallbackKeys.OPEN_FAVORITE_GROUP_POPUP, (isOn) => {
            resolve(isOn);
            sendEvent('tap.callout_save', {...defaultLogParam, index: 2});
          });
          TMapSender.openFavoriteGroupPopup([params]);
        } else {
          TMapReceiver.setCallback(ECallbackKeys.FAVORITE, (isOn) => {
            dispatch(fetchUserData()); // callback받고 userdata 갱신 > 즐겨찾기 활성화
            resolve(isOn);
            sendEvent('tap.callout_save', {...defaultLogParam, index: isOn ? 0 : 1});
          });
          // address, tel, rpFlag 는 기존 티지금 poi데이터에 없고, 즐겨찾기에 영향없는 것으로 보여 ignore. 필요시 api 추가요청 할 것.
          // @ts-ignore
          TMapSender.toggleFavorite(params);
        }
      });
    },
    [dispatch, sendEvent]
  );

  const checkFavorite = useCallback(
    (poiItem: Pick<TPoiDetailParam, 'poiId' | 'pkey' | 'navX' | 'navY'>) => {
      const {favorites, favoritesPublic} = userInfo.personalPlace.data;
      const favoriteItems = [...favorites, ...favoritesPublic];

      return favoriteItems.some(
        // @ts-ignore
        (item) => item.pkey === poiItem.pkey || item.poiId === poiItem.poiId
      );
    },
    [userInfo.personalPlace.data]
  );

  // 서울, 경기만 예약/웨이팅 표시
  const isReservationAvailableRegion = useMemo(() => {
    const currentRegionName1 = tplacehome.aroundMeData.result.data.requestGeoInfo.regionName1;
    const waitingAvailableRegion = ['서울특별시', '경기도'];
    return waitingAvailableRegion.includes(currentRegionName1);
  }, [tplacehome.aroundMeData.result.data.requestGeoInfo.regionName1]);
  const computedAdditionalFilterList = useMemo(() => {
    if (isReservationAvailableRegion) {
      return additionalInfoList;
    } else {
      return additionalInfoList.filter((item) => item.value !== EAdditionalInfo.RESERVATION);
    }
  }, [isReservationAvailableRegion]);

  return {
    openPoiDetail,
    toggleFavorite,
    checkFavorite,
    isReservationAvailableRegion,
    computedAdditionalFilterList,

    pageId,
    logInit,
    sendExpose,
    sendEvent,
    sendEventDiscovery,
    sendEventAroundMe,
    sendCurrationTapEvent,
    updatePageId,
    getDrawerModeIndex,
  };
};

export default usePlaceHome;
