import {HTMLAttributes, useCallback, useState} from 'react';
import classNames from 'classnames';
import {useAppSelector} from 'ducks/hooks';
import useLogger from 'hooks/useLogger';
import {useOnce} from 'hooks/useOnce';
import {EAppExtraCode, EAppRequestMode, EAppRequestTypeNew, ESkeletonType} from 'types/App';
import {ETLAServiceId} from 'constant/Log';
import {PlaceCategoryLink} from './PlaceCategoryLink';
import Skeleton from './Skeleton';

import style from 'styles/components/PlaceTagList.module.scss';
import useRedDot from 'hooks/useRedDot';
import {ERedDotItemGroup} from '@lcc/tmap-inapp';

type TProps = HTMLAttributes<HTMLDivElement>;

export const PlaceTagList = ({...wrapProps}: TProps) => {
  const {sendClickLogWithMapView} = useLogger();
  const redDotManager = useRedDot({itemGroup: ERedDotItemGroup.PLACE});

  const {remoteConfig, centerLatLon, visibleRedDotItemName, remoteUpdateTime} = useAppSelector(
    (state) => ({
      centerLatLon: state.map.lastCachedCenter ||
        state.map.userPosition || {lat: undefined, lon: undefined},
      lastZoom: state.map.zoom,
      visibleRedDotItemName: state.category.visibleRedDotItemName,
      remoteConfig: state.remote,
      remoteUpdateTime: state.remote.lastUpdateTime || 0,
    })
  );

  const tooltipFilter = remoteConfig.tnowCategoryLink.find(
    (t) => !!t.tnow_tooltip && !!t.tnow_reddot && visibleRedDotItemName.includes(t.tnow_reddot)
  );
  const [hasTooltipArea, setTooltipArea] = useState(false);
  const [isHideAnimation, setHideAnimation] = useState(false);

  const handleScroll = useCallback(() => {
    setHideAnimation(true);
  }, []);

  useOnce(tooltipFilter, () => {
    setTooltipArea(!!tooltipFilter);
  });

  return (
    <Skeleton
      apiStatus={{
        data: remoteConfig.rawRemoteData,
        loading: remoteUpdateTime < 1,
        loaded: remoteUpdateTime > 0,
      }}
      type={ESkeletonType.QUICK_LINK}
    >
      <div
        className={style.tag_list}
        {...wrapProps}
        onScroll={!!(hasTooltipArea || isHideAnimation) ? handleScroll : undefined}
      >
        {remoteConfig.tnowCategoryLink.map((c, i) => {
          const hasRedDot = c.tnow_reddot && redDotManager.getItem(c.tnow_reddot);

          return (
            <div
              className={classNames({
                [style.has_tooltip_area]: hasTooltipArea,
              })}
              key={i}
            >
              <PlaceCategoryLink
                onClick={() => {
                  setTooltipArea(false);
                  hasRedDot && c.tnow_reddot && redDotManager.updateItem(c.tnow_reddot);
                  sendClickLogWithMapView('tap.near_poi_category', {search_query: c.type});
                }}
                landingUrl={c.url}
                type={c.type}
                queries={{
                  centerLat: centerLatLon.lat,
                  centerLon: centerLatLon.lon,
                  tailParam: {
                    log_all_referrer: ETLAServiceId.PLACE,
                  },
                  reqMode: EAppRequestMode.MAIN,
                  reqType: EAppRequestTypeNew.NO_DATA,
                  extra: EAppExtraCode.SETTING_DEFAULT,
                }}
                className={classNames(style.item, {
                  [style.red_dot]: hasRedDot,
                  [style.has_tooltip_area]: hasTooltipArea,
                })}
              >
                {c.icon && <img alt={c.label} className={style.icon} src={c.icon} />}
                <span>{c.label}</span>
              </PlaceCategoryLink>

              {hasRedDot && hasTooltipArea && c.type === tooltipFilter?.type && (
                <div
                  onAnimationEnd={() => {
                    setTooltipArea(false);
                  }}
                  onTransitionEnd={() => {
                    setTooltipArea(false);
                  }}
                  className={classNames(style.tooltip, {
                    [style.is_hide]: isHideAnimation,
                  })}
                >
                  <span className={style.arrow} />
                  <p>{c.tnow_tooltip}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Skeleton>
  );
};

export default PlaceTagList;
