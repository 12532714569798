import classNames from 'classnames';
import {useEffect, useMemo, useState, PropsWithChildren} from 'react';
import {ESkeletonType} from 'types/App';
import {times} from 'utils/lodash';
import s from 'styles/components/Skeleton.module.scss';
import {TApiStatus} from 'types/Api';

type TProps = PropsWithChildren<{
  apiStatus: TApiStatus<any>;
  type: ESkeletonType;
  className?: string;
}>;

const Skeleton = ({apiStatus, children, type, className = ''}: TProps) => {
  const [isShow, setShow] = useState<boolean>(true);
  const [isRemoved, setRemove] = useState<boolean>(false);
  const isQuickLinkType = useMemo(() => {
    return [ESkeletonType.SEARCH_QUICK_LINK, ESkeletonType.QUICK_LINK].includes(type);
  }, [type]);

  const showChild = useMemo(() => {
    if (
      [
        ESkeletonType.PLACE_RESULT_LIST,
        ESkeletonType.POI_MAIN_LIST,
        ESkeletonType.SEARCH_QUICK_LINK,
        ESkeletonType.SEARCH_RECOMMENDATION,
        ESkeletonType.SEARCH_RECOMMENDATION_OPEN,
        ESkeletonType.FESTIVAL_LIST,
      ].includes(type)
    ) {
      const isPending = apiStatus.loading === false && apiStatus.loaded === false;

      if (isPending) {
        return false;
      }

      if (apiStatus.error) {
        return true;
      }

      if (apiStatus.data.list?.length > 0) {
        return true;
      }

      if (apiStatus.loading) {
        return false;
      }

      if (apiStatus.loaded) {
        return true;
      }
    }

    return true;
  }, [type, apiStatus]);

  useEffect(() => {
    if (isShow && (apiStatus.loaded || apiStatus.error)) {
      setRemove(true);
      setShow(false);
    }
  }, [isShow, apiStatus.loaded, apiStatus.error]);

  useEffect(() => {
    if (
      ![
        ESkeletonType.PLACE_RESULT_LIST,
        ESkeletonType.SEARCH_QUICK_LINK,
        ESkeletonType.CATEGORY_HEADER,
        ESkeletonType.CATEGORY_NO_FILTER_HEADER,
        ESkeletonType.POI_MAIN_LIST,
        ESkeletonType.SEARCH_HEADER,
        ESkeletonType.CALLOUT_POPUP,
        ESkeletonType.FESTIVAL_LIST,
      ].includes(type)
    ) {
      return;
    }

    if (apiStatus.loading && type === ESkeletonType.CALLOUT_POPUP) {
      setRemove(false);
      setShow(true);

      return;
    }

    // 필터 변경 시
    if (apiStatus.loading && apiStatus.data.list.length === 0) {
      setRemove(false);
      setShow(true);

      return;
    }
  }, [apiStatus.loading, apiStatus.data, type]);

  const Component = useMemo(() => {
    switch (type) {
      case ESkeletonType.CATEGORY_HEADER:
        return (
          <div className={s.skeleton_category_header_wrap}>
            <div className={s.bottom}>
              <div className={s.item} />
              <div className={s.item} />
              <div className={s.item} />
            </div>
          </div>
        );

      case ESkeletonType.CATEGORY_NO_FILTER_HEADER:
        return <div className={s.skeleton_category_header_wrap} />;

      case ESkeletonType.PLACE_RESULT_LIST:
        return (
          <div className={s.skeleton_list_wrap}>
            {times(2).map((n) => (
              <div className={s.skeleton_item_wrap} key={n}>
                <div className={s.position}>
                  <div className={s.skeleton_title} />
                  <div className={s.skeleton_desc} />
                </div>
              </div>
            ))}
          </div>
        );

      case ESkeletonType.SEARCH_QUICK_LINK:
        return (
          <div className={s.skeleton_search_quick_link}>
            <div className={s.item}>
              <i />
              <span />
            </div>
            <div className={s.item}>
              <i />
              <span />
            </div>
          </div>
        );

      case ESkeletonType.QUICK_LINK:
        return (
          <div className={s.skeleton_place_quick_link}>
            <div className={s.item}>
              <i />
              <span />
            </div>
            <div className={s.item}>
              <i />
              <span />
            </div>
            <div className={s.item}>
              <i />
              <span />
            </div>
          </div>
        );

      case ESkeletonType.SEARCH_HEADER:
        return (
          <div className={s.skeleton_search_header}>
            <div className={s.skeleton_filter} />
          </div>
        );

      case ESkeletonType.POI_MAIN_LIST:
        return (
          <div className={s.skeleton_poi_main_list}>
            <div className={s.skeleton_banner} />
            {times(2).map((n) => (
              <div className={s.skeleton_poi_item} key={n}>
                <div className={s.skeleton_title} />
                <div className={s.skeleton_desc} />
              </div>
            ))}
          </div>
        );

      case ESkeletonType.SEARCH_PICK_ON_MAP:
        return (
          <div className={s.skeleton_pick_on_map}>
            <i />
            <span />
          </div>
        );

      case ESkeletonType.SEARCH_MAIN:
        return (
          <div className={s.skeleton_search_main}>
            <div className={s.quick_link}>
              <span className={s.item} />
              <span className={s.item} />
              <span className={s.item} />
            </div>
            <div className={s.list}>
              {times(3).map((i) => (
                <div className={s.item} key={i} />
              ))}
            </div>
          </div>
        );

      case ESkeletonType.CALLOUT_POPUP:
        return (
          <div className={s.skeleton_callout}>
            <p className={s.title} />
            <p className={s.desc} />
            <p className={s.button}>
              <span />
              <span />
            </p>
          </div>
        );

      case ESkeletonType.SEARCH_RECOMMENDATION:
        return (
          <div className={s.skeleton_search_recommendation}>
            <div className={s.item} />
          </div>
        );

      case ESkeletonType.SEARCH_RECOMMENDATION_OPEN:
        return (
          <div className={s.skeleton_search_recommendation_open}>
            <div className={s.title} />
            <div className={s.items}>
              <div className={s.item} />
              <div className={s.item} />
              <div className={s.item} />
            </div>
          </div>
        );

      case ESkeletonType.FAVORITE_LIST:
        return (
          <div className={s.skeleton_favorite_list}>
            {times(4).map((i, idx) => (
              <div className={s.item} key={idx}>
                <i />
                <span />
              </div>
            ))}
          </div>
        );

      case ESkeletonType.SEARCH_RANKING_DROPDOWN:
        return (
          <div className={s.skeleton_search_ranking_dropdown}>
            {times(3).map((i, idx) => (
              <div className={s.item} key={idx} />
            ))}
          </div>
        );

      case ESkeletonType.FESTIVAL_LIST:
        return (
          <div className={s.skeleton_festival_list}>
            {times(2).map((i, idx) => (
              <div className={s.item} key={idx}>
                <div className={s.thumbnail} />
                <div className={s.content}>
                  <div className={s.title} />
                  <div className={s.desc} />
                </div>
              </div>
            ))}
          </div>
        );
    }
  }, [type]);

  return (
    <div
      style={{position: 'relative'}}
      className={classNames('skeleton-ui', className || '')}
      data-loaded={showChild}
    >
      {!isRemoved && (
        <div
          className={classNames(s.skeleton_wrap, {
            [s.alpha0]: isQuickLinkType,
          })}
          onTransitionEnd={() => setRemove(true)}
        >
          <div
            className={classNames(s.skeleton, {
              [s.fade_out]: !isShow,
              [s.ignore_blink]: isQuickLinkType,
            })}
          >
            {Component}
          </div>
        </div>
      )}
      {showChild && children}
    </div>
  );
};

export default Skeleton;
