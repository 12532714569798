import {PropsWithChildren, useMemo} from 'react';
import classNames from 'classnames';
import {IcoArrowDown} from '../../@tmds/icons/IcoArrowDown';
import {useAppSelector} from '../../../ducks/hooks';

import s from '../../../styles/components/tplacehome/TPlaceDiscoveryOld.module.scss';

type TProps = {
  mainTitle: string;
  subTitle: string;
  isSelector?: boolean;
  noFirstLetterHighlight?: boolean;
  pb?: number;
  handleClick?: () => void;
  name?: string;
} & PropsWithChildren<{}>;

const TPlaceDiscoverySection = (props: TProps) => {
  const {mainTitle, subTitle, isSelector, noFirstLetterHighlight, pb, children, handleClick} =
    props;

  const {tplacehome} = useAppSelector((state) => state);

  const currentRegionNameInfo = useMemo(() => {
    return {
      depth1:
        tplacehome.discoveryData.selectedRegionNameInfo.depth1 ||
        tplacehome.discoveryData.userRegionInfo?.foundAreaInfo.defaultRegion1Name ||
        '',
      depth2: tplacehome.discoveryData.selectedRegionNameInfo.depth2 || '',
    };
  }, [
    tplacehome.discoveryData.selectedRegionNameInfo.depth1,
    tplacehome.discoveryData.selectedRegionNameInfo.depth2,
    tplacehome.discoveryData.userRegionInfo?.foundAreaInfo.defaultRegion1Name,
  ]);

  return (
    <section className={s.section} style={{paddingBottom: `${pb}px`}} data-name={props.name || ''}>
      <header className={s.header}>
        <h1 className={s.title}>
          <span className={classNames(s.sub, {[s.no_highlight]: noFirstLetterHighlight})}>
            {subTitle}
          </span>
          <em className={s.main}>
            {isSelector && (
              <button type="button" className={s.select} onClick={handleClick}>
                {currentRegionNameInfo.depth1} {currentRegionNameInfo.depth2}
                <IcoArrowDown width={14} height={14} color={'tmobiBlue600'} />
              </button>
            )}
            {mainTitle}
          </em>
        </h1>
      </header>
      {children}
    </section>
  );
};

export default TPlaceDiscoverySection;
