import {useCallback, useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {TDiscoveryItem} from 'ducks/tplacehome/types';
import {useAppSelector} from 'ducks/hooks';
import usePlaceHome from 'hooks/usePlaceHome';
import Thumbnail from 'components/Thumbnail';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import TmdsTag from 'components/@tmds/TmdsTag';
import {IcStarFill} from 'components/@tmds/icons/v1.2/IcStarFill';
import {IcStar} from 'components/@tmds/icons/v1.2/IcStar';

import s from 'styles/components/tplacehome/TPlaceDiscoveryPoiItemRow.module.scss';
import {IcLineBetween} from 'components/@tmds/icons/v1.2/IcLineBetween';
import {setComma, getDistance} from 'utils/formatter';
import {tplacehomeUtils} from '../utils';
import DiscoveryDividerIcon from './comps/DividerIcon';

const TAG_LIMIT_WINDOW_WIDTH = 600;
const MAX_VISIBLE_TAGS = 3;

type TProps = {
  data: TDiscoveryItem;
  isCategoryTag?: boolean;
  isFavorite?: boolean;
  onClickDetail?: () => void;
  moduleName?: string;
  itemIndex: number;
};

const DiscoveryRowPoiItem = ({data, isCategoryTag, onClickDetail, ...props}: TProps) => {
  const {poiName, imageUrl, reviewDto, svcCategoryName, tags, distance, regionName1, regionName2} =
    data;
  const {reviewCount, reviewGrade} = reviewDto;
  const {isParking, isValet, isTMapPopular, isTVrestaurant, isReservation, isWaiting} = tags;

  const {width: windowWidth} = useAppSelector((state) => state.layout.windowSize);
  const appSize = useAppSelector((state) => state.layout.appSize);

  const placehomeHook = usePlaceHome();
  const [isFavorite, setIsFavorite] = useState(false);
  const handleToggleFavorite = useCallback(() => {
    placehomeHook
      .toggleFavorite(data, {itemIndex: props.itemIndex, moduleName: props.moduleName})
      .then((isOn) => {
        setIsFavorite(isOn ? true : false);
      });
  }, [data, placehomeHook, props.itemIndex, props.moduleName]);

  const handleMoveToDetail = useCallback(() => {
    onClickDetail?.();
    placehomeHook.openPoiDetail(data);
  }, [data, onClickDetail, placehomeHook]);

  const tagInfoList = useMemo(
    () =>
      tplacehomeUtils.getTagDisplayInfos({
        isPopular: isTMapPopular,
        isTVrestaurant,
        isTmapReservation: isReservation,
        isTmapWaiting: isWaiting,
        isParking,
        isValetParking: isValet,
      }),
    [isParking, isValet, isTMapPopular, isTVrestaurant, isReservation, isWaiting]
  );

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  const renderReview = () => {
    // 리뷰수가 10개 이상일때만 별점 노출
    // https://tmobi.atlassian.net/browse/TPLACEHOME-519
    if (reviewGrade && reviewCount && reviewCount >= 10) {
      return (
        <>
          <em className={s.star_point}>
            <IcStarFill width={16} height={16} color={'tmobiTeal400'} />
            {(reviewGrade || 0).toFixed(1)}
          </em>
          <span>({setComma(reviewCount)})</span>
        </>
      );
    } else if (reviewCount) {
      return (
        <span>
          리뷰 <em>{setComma(reviewCount)}</em>
        </span>
      );
    } else if (distance) {
      return (
        <span>
          현위치에서 <em>{getDistance(distance)}</em>
        </span>
      );
    } else {
      <span>-</span>;
    }
  };

  return (
    <div className={s.wrap} data-discovery-item={data.poiId}>
      <button type="button" className={s.content} onClick={handleMoveToDetail}>
        <div className={s.thumb}>
          <Thumbnail
            imgSrc={getImageUrl(imageUrl, EImageSize.THUMBNAIL)}
            alt={`${poiName} 이미지`}
            className={s.img}
          />
        </div>
        <div className={s.info}>
          <em className={s.name}>{poiName}</em>
          <div className={s.star_review_container}>
            {renderReview()}
            {data.visitCount3Month && (
              <>
                <IcLineBetween width={12} height={12} color="gray200" />
                <span className={classNames(s.route_num, s.ellipsis)}>
                  3개월간 길안내 <em>{setComma(data.visitCount3Month)}번</em>
                </span>
              </>
            )}
          </div>
          <span className={s.category_container}>
            <span>
              {regionName1} {regionName2}
            </span>

            <span className={s.dot}>
              <DiscoveryDividerIcon color="#E0E2E8" />
            </span>
            <span>{svcCategoryName}</span>
          </span>
          {tagInfoList.length > 0 && (
            <div className={s.tags}>
              {tagInfoList.map((tag, index) => {
                if (
                  (appSize.isLandscape || windowWidth <= TAG_LIMIT_WINDOW_WIDTH) &&
                  tagInfoList.length >= MAX_VISIBLE_TAGS + 1 &&
                  index >= MAX_VISIBLE_TAGS
                ) {
                  return index === MAX_VISIBLE_TAGS ? (
                    <TmdsTag
                      key={index}
                      label={`+${tagInfoList.length - MAX_VISIBLE_TAGS}`}
                      color={'gray600'}
                      fontSize={11}
                      backgroundColor={'gray50'}
                      className={s.tag}
                      filled
                    />
                  ) : null;
                }

                return (
                  <TmdsTag
                    key={index}
                    label={tag.label}
                    color={tag.color || 'gray600'}
                    fontSize={11}
                    backgroundColor={tag.backgroundColor || 'gray50'}
                    className={s.tag}
                    filled
                  />
                );
              })}
            </div>
          )}
        </div>
      </button>
      <button type="button" className={classNames(s.favorite)} onClick={handleToggleFavorite}>
        {isFavorite ? (
          <IcStarFill width={24} height={24} color={'yellow400'} />
        ) : (
          <IcStar width={24} height={24} color={'gray300'} />
        )}
      </button>
    </div>
  );
};

export default DiscoveryRowPoiItem;
