/**
 * 공통
 */
export enum ETPlaceTab {
  AROUND_ME = 'AROUND_ME',
  DISCOVERY = 'DISCOVERY',
}

/**
 * 내 주변
 */
export type AroundMeApiParams = {
  destLon: number;
  destLat: number;
  userRealLon: number;
  userRealLat: number;
  radius: TRadius;
  pageNo?: number;
  pageSize?: number;
  timeOfDay: TimeOfDay;
  age?: Age;
  gender?: Gender;
  featureOr?: string;
};

export type TRadius = 500 | 1000 | 5000 | 10000;
export type TimeOfDay = 'morning' | 'lunch' | 'afternoon' | 'dinner';
export type Gender = Nullable<'m' | 'f'>;
export type Age = Nullable<10 | 20 | 30 | 40 | 50 | 60>;

export enum EAdditionalInfo {
  OPENED = 'OPENED',
  RESERVATION = 'RESERVATION',
  PARKING = 'PARKING',
}

export enum ECategoryType {
  RESTAURANT = 'RESTAURANT',
  CAFE = 'CAFE',
  BAR = 'BAR',
}

export const additionalInfoList: {value: EAdditionalInfo; name: string}[] = [
  {
    value: EAdditionalInfo.OPENED,
    name: '영업중',
  },
  {
    value: EAdditionalInfo.RESERVATION,
    name: '예약/웨이팅',
  },
  {
    value: EAdditionalInfo.PARKING,
    name: '주차',
  },
];
export const categoryTypeList: {value: ECategoryType; name: string}[] = [
  {
    value: ECategoryType.RESTAURANT,
    name: '음식점',
  },
  {
    value: ECategoryType.CAFE,
    name: '카페',
  },
  {
    value: ECategoryType.BAR,
    name: '술집',
  },
];

export type TAroundMeSelectableParams = Pick<
  AroundMeApiParams,
  'radius' | 'timeOfDay' | 'age' | 'gender'
>;

/**
 * 발견
 */
/**
 * {
  regionFullCode: '4113511000',
  region1FullCode: '4100000000',
  region2FullCode: '4113500000',
  region3FullCode: '4113511000',
  region1Code: '41',
  region2Code: '135',
  region3Code: '110',
  region1Name: '경기',
  region2Name: '성남시 분당구',
  region3Name: '백현동',
  foundAreaInfo: {
    defaultRegion1Name: '강원',
    region2NameList: ['동해', '속초', '양양', '원주', '춘천'],
  },
};
 */
export type TDiscoveryParams = {
  regionName1?: string;
  regionName2?: string;
  rows?: number;
  userLat: number | undefined;
  userLon: number | undefined;
  categories?: string[];
};
export type TDiscoveryRegerseGeo = {
  regionFullCode: string;
  region1FullCode: string;
  region2FullCode: string;
  region3FullCode: string;
  region1Code: string;
  region2Code: string;
  region3Code: string;
  region1Name: string;
  region2Name: string;
  region3Name: string;
  foundAreaInfo: {
    defaultRegion1Name: string;
    region2NameList: string[];
  };
};

export type TDiscoveryArea = {
  areaId: string;
  areaName: string;
  areaDepth1Code: string;
  areaDepth2Code: string;
  areaDepth3Code: string;
};

export type TDiscoveryAccommodationInfo = {
  price: string;
  isTmapSpecialPrice: boolean;
  isLowestPrice: boolean;
};

export enum ECategoryName {
  FOOD = '음식점',
  LANDMARK = '관광명소',
  CAFE = '카페',
  BAR = '술집',
  HOTEL = '숙박',
}

export type TDiscoveryItem = {
  poiId: number;
  poiName: string;
  navSeq: string;
  navX: number;
  navY: number;
  centerX: number;
  centerY: number;
  pkey: string;
  imageUrl: string;
  reviewDto: {
    reviewCount: number;
    reviewGrade: number;
  };
  svcCategoryName: string;
  category: string;
  tags: {
    isParking: boolean;
    isValet: boolean;
    isTMapPopular: boolean;
    isTVrestaurant: boolean;
    isReservation: boolean;
    isWaiting: boolean;
    isGoodPrice: boolean;
    isNewlyOpen: boolean;
  };
  distance: number;
  rank: number;
  accommodationInfo?: {
    howAboutHere?: TDiscoveryAccommodationInfo;
    onda?: TDiscoveryAccommodationInfo;
  };
  visitCount3Month?: number;
  regionName1?: string;
  regionName2?: string;
};
