import classNames from 'classnames';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import ss from 'styles/components/tplacehome/TPlaceSkeleton.module.scss';
import {times} from 'utils/lodash';
import DiscoveryPoiItemRowSkeleton from './DiscoveryPoiItemRowSkeleton';
import MoreButton from './MoreButton';

const DiscoveryWeekendSkeleton = () => {
  return (
    <>
      <div className={s.base_poi}>
        <div className={s.base_poi_cont}>
          <div className={classNames(s.title_wrap, ss.skeleton_wrap)}>
            <p
              className={classNames(s.sub_title, ss.skeleton_item)}
              style={{height: 16, width: 60}}
            >
              {' '}
            </p>
            <h2 className={classNames(s.title, ss.skeleton_item)} style={{height: 20, width: 170}}>
              {' '}
            </h2>
            <p className={classNames(s.desc, ss.skeleton_item)} style={{height: 20, width: 140}}>
              {' '}
            </p>
          </div>
          <div className={classNames(s.img_wrap, ss.skeleton_item)} style={{height: 64, width: 64}}>
            {' '}
          </div>
        </div>
      </div>
      <div className={classNames(s.rounded_content_wrap)}>
        <ul className={s.list_wrap}>
          {times(5).map((index) => {
            return (
              <li key={index} className={s.cell}>
                <DiscoveryPoiItemRowSkeleton />
              </li>
            );
          })}
        </ul>

        <MoreButton current="-" total="-" />
      </div>
    </>
  );
};

export default DiscoveryWeekendSkeleton;
