// generated from scripts/generate_tmds_icon.js
import {ReactComponent as IcInfoFillSvg} from 'resource/@tmdsicon/v1.2/ic_info_fill.svg';
import TmdsIcon, {TTmdsIconProps} from 'components/@tmds/TmdsIcon';

type TProps = Omit<TTmdsIconProps, 'Icon'>;

const IcInfoFill = (props: TProps) => {
  return <TmdsIcon {...props} Icon={<IcInfoFillSvg {...props} />} />;
};

export {IcInfoFill};
