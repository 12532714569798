import {IcArrowRightBold} from 'components/@tmds/icons/v1.2/IcArrowRightBold';
import s from 'styles/components/ranking/RankingFestivalSection.module.scss';
import RankingFestivalSectionItem from './RankingFestivalSectionItem';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'ducks/hooks';
import {EAddressMode} from 'types/Search';
import {useOnce} from 'hooks/useOnce';
import {fetchFestival} from 'ducks/festival/slice';
import TMapSender from '@lcc/tmap-inapp';
import InView from 'react-intersection-observer';
import useLogger from 'hooks/useLogger';
import {TActionId} from 'types/Log';
import {shuffle} from 'utils/lodash';

const RankingFestivalSection = () => {
  const {tRank, festival} = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [isInView, setIsInView] = useState(false);
  const {sendClickLogWithMapView} = useLogger();
  const areaInfo = useMemo(
    () => tRank.currentAddressMap[EAddressMode.CATE1],
    [tRank.currentAddressMap]
  );
  const itemList = useMemo(() => {
    return shuffle(festival.data.list);
  }, [festival.data.list]);
  const logParams = useMemo(() => {
    return {
      region_name: areaInfo?.areaName || '',
      list_num: itemList.length,
    };
  }, [areaInfo?.areaName, itemList.length]);

  const handleClickMore = useCallback(() => {
    sendClickLogWithMapView('tap.festival' as TActionId, logParams);
    TMapSender.openServiceByName('place', {
      productid: 'search',
      pageid: 'festival',
      extra: JSON.stringify({areaId: areaInfo?.areaId}),
    });
  }, [areaInfo?.areaId, logParams, sendClickLogWithMapView]);

  useOnce(!!areaInfo?.areaId, () => {
    dispatch(fetchFestival({regionDepth1Code: areaInfo?.areaDepth1Code, size: 10}));
  });

  useEffect(() => {
    isInView && sendClickLogWithMapView('view.festival' as TActionId, logParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  if (itemList.length < 3 || !areaInfo) {
    return null;
  }
  return (
    <InView as="div" className={s.wrap} onChange={(inView) => setIsInView(inView)}>
      <div className={s.header} onClick={handleClickMore}>
        <h2>{areaInfo?.areaName}에서 가볼만한 축제</h2>
        <IcArrowRightBold width={16} height={16} />
      </div>
      <ul className={s.list_wrap}>
        {itemList.map((item, index) => (
          <li className={s.cell}>
            <RankingFestivalSectionItem
              key={item.poiId}
              itemData={item}
              onClick={() => {
                sendClickLogWithMapView('tap.festival_poi', {
                  ...logParams,
                  list_seq: index + 1,
                  pkey: item.pkey,
                });
              }}
            />
          </li>
        ))}
      </ul>
    </InView>
  );
};

export default RankingFestivalSection;
