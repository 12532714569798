import classNames from 'classnames';
import s from 'styles/components/tplacehome/TPlaceDiscovery.module.scss';
import MoreButton from './MoreButton';
import {times} from 'utils/lodash';
import DiscoveryPoiItemColumnSkeleton from './DiscoveryPoiItemColumnSkeleton';

const DiscoverySimilarSkeleton = () => {
  return (
    <>
      <div className={s.base_poi}>
        <div className={s.bg_gradient} style={{background: '#171819'}} />
      </div>
      <div className={classNames(s.rounded_content_wrap, s.content_wrap)}>
        <ul className={s.list_wrap}>
          {times(4).map(() => {
            return (
              <li className={s.cell}>
                <DiscoveryPoiItemColumnSkeleton thumbHeight={110} />
              </li>
            );
          })}
        </ul>
        <MoreButton current="-" total="-" />
      </div>
    </>
  );
};

export default DiscoverySimilarSkeleton;
