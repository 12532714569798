import classNames from 'classnames';
import {BottomPopupModal} from 'components/BottomPopupModal';
import useDimmedQuery from 'hooks/useDimmedQuery';
import {useCallback} from 'react';
import {IcArrowDownBold} from 'components/@tmds/icons/v1.2/IcArrowDownBold';
import {IcCheckBold} from 'components/@tmds/icons/v1.2/IcCheckBold';

import s from 'styles/components/search/SearchFestivalHeaderFilter.module.scss';
import {useAppSelector} from 'ducks/hooks';
import {EEventHolding, ESortOption} from 'ducks/festival/types';
import {sendSearchClickLog} from 'utils/logManager';
import {TActionId} from 'types/Log';

type TProps = {
  onFilterChange: (filter: EEventHolding | EFilterOption.ALL) => void;
  onSortChange: (sort: ESortOption) => void;
};

export enum EFilterOption {
  ALL = 'ALL',
  NOW = 'NOW',
  BEFORE = 'BEFORE',
}

type TFilter = {
  label: string;
  key: EEventHolding | EFilterOption.ALL;
};

const FILTER_MAP: Partial<Record<EFilterOption, TFilter>> = {
  [EFilterOption.ALL]: {
    label: '전체',
    key: EFilterOption.ALL,
  },
  [EFilterOption.NOW]: {
    label: '진행중',
    key: EEventHolding.NOW,
  },
  [EFilterOption.BEFORE]: {
    label: '진행예정',
    key: EEventHolding.BEFORE,
  },
};

type TSort = {
  label: string;
  key: ESortOption;
};

const SORT_MAP: Partial<Record<ESortOption, TSort>> = {
  [ESortOption.POPULARITY]: {
    label: '인기순',
    key: ESortOption.POPULARITY,
  },
  [ESortOption.DISTANCE]: {
    label: '거리순',
    key: ESortOption.DISTANCE,
  },
};

const SearchFestivalHeaderFilter = ({onFilterChange, onSortChange}: TProps) => {
  const {eventHolding: filter, sort} = useAppSelector((state) => state.festival);
  const sortSelectDimmed = useDimmedQuery({filter: 'festival-sort'});

  const handleClickSortItem = useCallback(() => {
    sendSearchClickLog('tap.order');

    sortSelectDimmed.open();
  }, [sortSelectDimmed]);

  const handleChangeFilter = useCallback(
    (key: EEventHolding | EFilterOption.ALL, isOn: boolean) => () => {
      const logAction: Record<EEventHolding | EFilterOption.ALL, TActionId> = {
        [EFilterOption.ALL]: 'tap.filter_all',
        [EEventHolding.NOW]: 'tap.filter_ongoing',
        [EEventHolding.BEFORE]: 'tap.filter_standby',
      };
      sendSearchClickLog(logAction[key]);

      if (!isOn) {
        onFilterChange(key);
      }
    },
    [onFilterChange]
  );

  const handleChangeSort = useCallback(
    (key: ESortOption, isOn: boolean) => () => {
      const logAction: Record<ESortOption, TActionId> = {
        [ESortOption.POPULARITY]: 'sort_tap.score',
        [ESortOption.DISTANCE]: 'sort_tap.dist',
      };

      sendSearchClickLog(logAction[key]);

      sortSelectDimmed.close();
      if (!isOn) {
        onSortChange(key);
      }
    },
    [onSortChange, sortSelectDimmed]
  );

  return (
    <>
      <div className={s.wrap}>
        <div className={s.filter_wrap}>
          {Object.keys(FILTER_MAP).map((key) => {
            const item = FILTER_MAP[key as EFilterOption];
            const currentFilter = filter || EFilterOption.ALL;
            const isActive = item?.key === currentFilter;
            return item ? (
              <div
                key={key}
                className={classNames(s.filter_item, {
                  [s.active]: isActive,
                })}
                onClick={handleChangeFilter(item.key, isActive)}
              >
                <span className={s.text}>{item.label}</span>
              </div>
            ) : null;
          })}
        </div>
        <div className={s.sort_item} onClick={handleClickSortItem}>
          {SORT_MAP[sort]?.label}
          <IcArrowDownBold width={12} height={12} color="gray500" />
        </div>
      </div>

      <BottomPopupModal isOpen={sortSelectDimmed.isOpen}>
        <div className={s.modal_wrap}>
          {Object.keys(SORT_MAP).map((key) => {
            const item = SORT_MAP[key as ESortOption];
            const isActive = item?.key === sort;
            return item ? (
              <div
                key={key}
                className={classNames(s.sort_select_item, {
                  [s.active]: isActive,
                })}
                onClick={handleChangeSort(item.key, isActive)}
              >
                {item.label}
                {isActive && <IcCheckBold color="tmobiBlue500" />}
              </div>
            ) : null;
          })}
        </div>
      </BottomPopupModal>
    </>
  );
};

export default SearchFestivalHeaderFilter;
