import classNames from 'classnames';

import s from 'styles/components/tplacehome/TPlaceDiscoveryPoiItemRow.module.scss';
import ss from 'styles/components/tplacehome/TPlaceSkeleton.module.scss';

type TProps = {};

const DiscoveryRowPoiItemSkeleton = (props: TProps) => {
  return (
    <div className={s.wrap}>
      <button type="button" className={s.content}>
        <div className={classNames(s.thumb, ss.skeleton_item)}>{/* ====== */}</div>
        <div className={s.info}>
          <em className={classNames(s.name, ss.skeleton_item)} style={{height: 24, width: 90}}>
            {''}
          </em>
          <div
            className={classNames(s.star_review_container, ss.skeleton_item)}
            style={{height: 16, width: 200}}
          >
            {''}
          </div>
          <span
            className={classNames(s.category_container, ss.skeleton_item)}
            style={{height: 14, width: 120}}
          >
            {''}
          </span>
          {/* <div className={classNames(s.tags, ss.skeleton_item)}>======</div> */}
        </div>
      </button>
    </div>
  );
};

export default DiscoveryRowPoiItemSkeleton;
